<template>
    <div class="mb-4">
        <div class="title-box vertical-align">
            <b-row class="title">
              <span class="breadcrumb-title">{{breadCrumbTitle}}</span>
              <h1>{{title}}</h1>
            </b-row>
        </div>
        <div class="title-box">
            <b-button-toolbar v-if="buttons.length > 0" class="title">
              <b-button v-for="btn in buttons" :key="btn.name" :name="btn.name" @click="navPage($event)" variant="arm-primary">{{btn.label}}</b-button>
            </b-button-toolbar>
        </div>
    </div>
</template>

<script>
    import { mapState } from "vuex"
    export default {
        name: "TagHeader",
        props: {
            title:           { default: "", type: String },
            breadCrumbTitle: { default: "", type: String },
            buttons:         {default: function () { return [] }, type: Array}
        },
        computed: {
            ...mapState("auth",["details"]),
        },
        methods: {
            navPage (event) {
                const pageName = event.currentTarget.name
                if (pageName == "login" || pageName == "logout") {
                    if(pageName == "logout")
                        this.$store.dispatch("auth/logout")
                    this.$router.push({name: pageName})
                } else if (pageName == "back") {
                    this.$router.back()
                } else {
                    this.$router.push({path: `/${pageName}/${this.details.username}`})
                }
            }
        }
    }
</script>

<style scoped>
</style>
