<template>
  <b-modal
    id="username-update-modal"
    centered
    no-fade
    no-close-on-backdrop
    no-close-on-esc
  >
    <template #modal-header>
      <div class="header title">
        Please confirm
      </div>
    </template>
    It appears that you are attempting to change your username as described below:

    <div class="font-weight-bold my-2">
      <b-row>
        <b-col>
          Previous username:
        </b-col>
        <b-col>
          {{ previousUserName }}
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          New username:
        </b-col>
        <b-col>
          {{ userName }}
        </b-col>
      </b-row>
    </div>

    Please note the following before updating your username:
    
    <ul>
      <li>
        <b>You will be logged out</b>
        and will need to login again to ARM web applications using your new username
      </li>
      <li>
        You will no longer be able to login using the previous username
      </li>
      <li>
        If your previous username conforms to current ARM account username requirements, it will be available to other ARM accounts
      </li>
      <li>
        If your previous username does not conform to current ARM account username requirements, you must change it now
      </li>
    </ul>
    
    If you wish to cancel this update and return to the profile form, click "Back to profile" below.
    If you wish to continue and update your profile, including your username, click "Submit" below.
    <template #modal-footer>
      <div class="w-100 text-right">
        <b-button
          variant="secondary"
          @click="hide"
        >
          Back to profile
        </b-button>
        <b-button
          variant="success"
          @click="confirm"
        >
          Submit and log out
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  props: {
    userName: {
      default: "",
      type: String,
    },
    previousUserName: {
      default: "",
      type: String,
    },
  },
  methods: {
    hide() {
      this.$bvModal.hide("username-update-modal")
    },
    confirm() {
      this.$emit("confirmUpdate")
      this.hide()
    },
  },
}
</script>

<style scoped>
.header {
  font-size: 1.2em;
}

.title {
  font-family: Oswald, Tahoma, sans-serif;
  /* text-transform: uppercase; */
  /* font-weight: 700 !important; */
}
</style>
