<template>
    <b-container fluid>
        <TagHeader :title="title"></TagHeader>
        <div class="section1">
            <div v-if="verified" class="section bordered">
                <div class="content" data-section-name="reset-password">
                    <h2>Password Requirements</h2>
                    <b-row class="mt-4">
                      <b-col><h3>Passwords must:</h3></b-col>
                    </b-row>
                    <b-row class="mt-4">
                      <b-col lg="6">
                        <ul class="thick ml-2">
                          <li :class="validateErrorClass(tenCharRule)">{{tenCharRule.message}}</li>
                        </ul>
                      </b-col>
                    </b-row>
                    <b-row class="mt-4">
                      <b-col><h3>And have at least 3 out of 4:</h3></b-col>
                    </b-row>
                    <b-row class="mt-4">
                    <b-col lg="6">
                      <ul class="thick ml-2">
                        <li v-for="rule in rules" :key="rule.message" :class="validateErrorClass(rule)">{{rule.message}}</li>
                      </ul>
                    </b-col>
                    </b-row>
                  <b-row class="mt-4">
                  <b-col lg="6"><FormInput label="New Password" :value="newPassword" name="newPassword" :required=required :showRequired=required @inputUpdated="updateInput" type="password" :state="isPasswordValid"/></b-col>
                  </b-row>
                  <b-row class="mt-4">
                    <b-col lg="6"><FormInput label="Verify Password" :value="verifyPassword" name="verifyPassword" :required=required :showRequired=required @inputUpdated="updateInput" type="password" :state="samePasswords" /></b-col>
                  </b-row>
                    <b-row class="mt-5">
                        <b-col offset-lg="3" lg="6"><b-button id="passwordReset" block variant="arm-primary" :disabled="!isDisabled" @click="updatePassword">Submit</b-button></b-col>
                    </b-row>
                    <b-row v-if="showResponse" class="mt-4">
                        <b-col lg="10">
                            <b-alert v-if="errorMessage" variant="danger" class="thick" show>
                                Sorry, an error occurred while saving your information. Please contact adc@arm.gov.
                            </b-alert>
                            <b-alert v-else variant="success" show>
                                <h3>Success!</h3>
                                Your password has been successfully updated with ARM. You may
                                continue to use the ARM Site.
                            </b-alert>
                        </b-col>
                    </b-row>
                </div>
            </div>
            <div v-else class="section bordered">
                <h2>The Page has been expired!</h2>
                <div class="content" data-section-name="verify-success">
                    <p>
                        Please contact us at adc@arm.gov or request a password reset.
                    </p>
                </div>
            </div>
        </div>
    </b-container>
</template>

<script>
    import TagHeader from "@/components/TagHeader"
    import FormInput from "@/components/FormInput"
    import axios from "axios";
    import Config from "../config";
    import _ from "underscore";
    export default {
        name: "ResetPassword",
        components: {
            TagHeader,
            FormInput
        },
        data() {
            return {
                title: "Reset Password",
                required: true,
                verified: true,
                newPassword: "",
                verifyPassword: "",
                threeOutOfFourRulePassed: false,
                rules: [
                  {message: "One upper-case character", regex:/[A-Z]+/},
                  {message: "One lower-case character", regex:/[a-z]+/},
                  {message: "One number", regex:/[0-9]+/},
                  {message: "One of the following special characters: '#?!@$%^&*-'", regex:/[#?!@$%^&*-]/},
                ],
                tenCharRule: {message: "Be at least 10 characters long", regex:/.{10,}/},
                username: null,
                token: null,
                errorMessage: "",
                showResponse: false
            }
        },
        computed: {
          isPasswordValid() {
            var errors = []
            _.each(this.rules, (rule) => {
              if (!rule.regex.test(this.newPassword))
                errors.push(rule.message)
            })
            this.checkThreeOutOfFourRule(errors)
            if (errors.length > 1) {
              return false
            } else {
              if (this.newPassword.length >= 10) {
                return true
              } else {
                return false
              }
            }
          },
            samePasswords () {
                return this.passwordsFilled() && (this.newPassword == this.verifyPassword) ? true : false
            },
            isDisabled() {
                return this.isPasswordValid && this.samePasswords ? true : false
            }
        },
        watch: {
            $route (to, from) {
                this.validateToken(to.params.params)
            }
        },
        mounted() {
            this.validateToken(this.$route.params.params)
        },
        methods: {
            checkThreeOutOfFourRule(errors) {
              this.threeOutOfFourRulePassed = errors.length < 2
            },
            passwordsFilled () {
                return (this.newPassword !== '' && this.verifyPassword !== '')
            },
            validateErrorClass(condition) {
              if (condition.regex.test(this.newPassword)) {
                return "valid"
              } else {
                if (condition.message === "Be at least 10 characters long") {
                  return "invalid"
                } else if (this.threeOutOfFourRulePassed) {
                  return null
                } else {
                  return "invalid"
                }
              }
            },
            updateInput(payload) {
                this[payload.label] = payload.value
            },
            validateToken(params) {
                this.username = params.split("/")[0].split("=")[1]
                this.token = params.split("/")[1].split("=")[1]
                if (!this.username || !this.token){
                    this.verified = false
                } else {
                  const payload = {
                    username: this.username,
                    verificationCode: this.token
                  };
                  const self = this;
                  axios({
                    method: "post",
                    url: `${Config.api.url}/users/validate/reset`,
                    data: payload
                  }).then((response) => {
                    self.verified = response.data === true ? true : false
                  }).catch((error) => {
                    self.verified = false
                  })
                }
            },
            updatePassword(ev) {
                ev.preventDefault()
                if (!this.samePasswords) {
                    console.log("The New and Verify Passwords entered doesn't match.")
                    return false
                } else {
                    var payload = {
                        username: this.username,
                        token: this.token,
                        password: this.newPassword,
                    }
                  var self = this
                  axios({
                    method: "post",
                    url: `${Config.api.url}/users/reset/password`,
                    data: payload
                  }).then((response) => {
                    self.showResponse = true
                    self.errorMessage = response.data
                  }).catch((error) => {
                    self.showResponse = true
                    self.errorMessage = error
                  })
                }
            },
            navPage () {
                var username = this.$route.params.params.split("/")[0].split("=")[1]
                this.$router.push({path: `/update/${username}`})
            }
        }
    }
</script>

<style scoped>
#passwordReset:disabled {
    cursor: not-allowed;
}
/*.invalid, .arm-required {*/
/*    color: red;*/
/*}*/

/*.valid {*/
/*    color: green;*/
/*}*/
</style>
