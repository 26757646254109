<template>
    <div>
        <div class="section2">
            <div class="section bordered">
                <b-row class="mt-4">
                    <b-col lg="4">
                        <label class="thick">Please enter your email address to get started:<span class="arm-required">*</span></label>
                    </b-col>
                    <b-col lg="6">
                        <b-form-input
                          v-model.trim="email"
                          class="rounded-0"
                          :state="validateEmailMsg"
                          :disabled="emailValidated"
                          type="email"
                          placeholder="Please enter your email"
                          aria-describedby="input-live-help email-feedback"
                          @blur="validateEmail"
                          @keyup.enter="validateEmail"
                          @input="validateInput"
                        />
                        <b-form-invalid-feedback id="email-feedback" class="thick">{{errorMessage}}</b-form-invalid-feedback>
                    </b-col>
                    <b-col v-if="emailValidated" lg="2" class="mt-1">
                        <font-awesome-icon icon="sync-alt" size="2x" title="Edit Email Address" @click="reloadPage"/>
                    </b-col>
                </b-row>
            </div>
        </div>


        <div v-if="emailValidated">
            <div class="section2">
                <div v-if="accountExists" class="section">
                    <div v-if="isValidAccountStatus">
                        <h3 class="mb-3">Current subscriptions/registrations:</h3>
                        <div v-if="isAccountTypeFull">
                            <b-form-checkbox v-model="checkExistingAccounts"><strong>Full ARM Account</strong> (<router-link to="/">update your account</router-link>)</b-form-checkbox>
                        </div>
                        <div v-if="isAccountTypeNewsletter">
                            <b-form-checkbox v-model="checkExistingAccounts">
                              Newsletter/Working Groups (<b-link @click="sendCodeAndVerifyEmail">update</b-link>)
                            </b-form-checkbox>
                            <h3 class="mt-3">Would you like to create a Full ARM Account?</h3>
                            <b-form-radio
                              v-model="newsSelected"
                              id="newsSelected"
                              value="A"
                              @change="showSelectedContent"
                            >
                              Create a Full Account
                            </b-form-radio>
                            <b-form-valid-feedback
                              :state="state"
                              class="ml-4 helpText"
                            >
                              {{facilityAccountText}}
                            </b-form-valid-feedback>
                        </div>
                        <div v-if="isAccountTypeDataAccess">
                            <b-form-checkbox v-model="checkExistingAccounts">
                              Data Access
                            </b-form-checkbox>
                            <h3 class="mt-3">Would you like to create a Full ARM Account?</h3>
                            <b-form-radio
                              v-model="newsSelected"
                              id="newsSelected"
                              value="A"
                              @change="showSelectedContent"
                            >
                              Create a Full Account
                            </b-form-radio>
                            <b-form-valid-feedback
                              :state="state"
                              class="ml-4 helpText"
                            >
                              {{facilityAccountText}}
                            </b-form-valid-feedback>
                        </div>
                    </div>
                    <div v-else>
                        <p><strong>{{email}}</strong> is already in the ARM User database in an inactive state. Please contact us to reset this request at adc@arm.gov</p>
                    </div>
                </div>
                <div v-else class="section">
                    <h3 class="mb-3">I would like to:<span class="arm-required">*</span></h3>
                    <b-form-group>
                        <b-form-radio
                          v-model="accountType"
                          value="A"
                          :disabled="isFieldDisabled"
                          class="thick mt-1"
                          @change="showSelectedContent"
                        >
                          Create a Full ARM Facility User Account
                        </b-form-radio>
                        <b-form-valid-feedback
                          :state="state"
                          class="ml-4 helpText"
                        >
                          {{facilityAccountText}}
                        </b-form-valid-feedback>
                        <b-form-radio
                          v-model="accountType"
                          value="M"
                          :disabled="isFieldDisabled"
                          class="thick mt-3"
                          @change="showSelectedContent"
                        >
                          Create a Limited ARM Facility User Account
                        </b-form-radio>
                        <b-form-valid-feedback
                          :state="state"
                          class="ml-4 helpText"
                        >
                          Keep up to date with the latest science and facility news, events, research highlights, posters, publications, working groups, and funding and employment opportunities.
                          <i>Note: for access to ARM data and applications, a Full ARM User Account is required. Please select the first option.</i>
                        </b-form-valid-feedback>
                    </b-form-group>

                </div>
            </div>
        </div>
        <Newsletter v-if="showNewsletter" :email="email"/>
        <AccountProfileContent v-if="showAccountProfile" :email="email" />
        <b-modal
          id="verification-modal"
          centered
          title="Verification Code"
          hide-header
          hide-footer
          no-fade
          @show="showModal"
          @hidden="resetModal"
        >
            <button type="button" aria-label="Close" class="close" @click="$bvModal.hide('verification-modal')">×</button>
            <b-form @submit="onSubmit">
            <h3 class="p-3 text-center">Verify Your Email Address</h3>
                <p class="ml-3 mr-3">A verification code has been sent to your email address: <strong>{{email}}</strong></p>
            <div class="p-3">
                <label class="thick">Verification Code<span class="arm-required">*</span></label>
                <b-form-input v-model.trim="verificationCode" placeholder="Enter verification code" required></b-form-input>
            </div>
            <b-row class="mt-2 mb-2 justify-content-md-center">
                <b-col cols="5">
                    <b-button type="submit" block pill variant="success" class="confirmBtn">Confirm Code</b-button>
                </b-col>
            </b-row>
                <div v-if="showError">
                    <p class="text-danger text-center"><font-awesome-icon icon="exclamation-circle" /> The code you entered is incorrect or expired</p>
                </div>
            </b-form>
        </b-modal>
    </div>
</template>

<script>
    import AccountProfileContent from "@/components/AccountProfileContent"
    import Newsletter from "@/components/Newsletter"
    import axios from "axios";
    import Config from "../config";

    export default {
        name: "ProfileContent",
        components: {
            AccountProfileContent,
            Newsletter
        },
        data() {
            return {
                isEmailUpdated: null,
                email: "",
                state: true,
                accountType: null,
                accountExists: false,
                emailValidated: false,
                showNewsletter: false,
                showAccountProfile: false,
                checkExistingAccounts: true,
                errorMessage: null,
                newsSelected: null,
                verificationCode: "",
                showError: null,
                isUpdateNewsletter: false,
                facilityAccountText: "Register with the Atmospheric Radiation Measurement (ARM) user facility to access data gathered during normal operations or field campaigns, make an in-person or virtual visit to a site, or propose and conduct a field campaign.",
                orcidRedirect: false,
            }
        },
        computed: {
            isAccountTypeFull() {
                return this.accountType === "A" || this.accountType === "F" ? true : false
            },
            isFieldDisabled() {
                return this.accountType ? true : false
            },
            isAccountTypeNewsletter() {
                return this.accountType === "M" || this.accountType === "T" ? true : false
            },
            isAccountTypeDataAccess() {
                return this.accountType === "R" ? true : false
            },
            isValidAccountStatus() {
              return this.isAccountTypeFull || this.isAccountTypeDataAccess || this.isAccountTypeNewsletter ? true : false
            },
            validateEmailMsg() {
                if (this.isEmailUpdated) {
                    if (this.errorMessage || !this.email)
                        return false
                    else {
                        var re = /^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9-]+)*(\\.[A-Za-z]{2,})$/igm;
                        return !re.test(this.email)
                    }
                } else {
                    return null
                }
            },
            newsletterLink() {
                return `/newsletter/${this.email}`
            }
        },
        mounted() {
          this.checkForOrcidRedirect()
        },
        methods: {
            reloadPage(){
                window.location.reload()
            },
            validateInput() {
                this.isEmailUpdated = false
                this.errorMessage = ""
            },
            showSelectedContent(val) {
                // console.log("changed selected content ====>", val)
                // console.log("selected content ====>", this.accountType)
                this.isUpdateNewsletter = false
                if (val === "A") {
                    if (this.accountExists && !this.orcidRedirect) {
                        this.showAccountProfile = false
                        this.showNewsletter = false
                        this.sendCode()
                    } else {
                        this.showAccountProfile = true
                        this.showNewsletter = false
                    }
                } else if (val === "M" || val === "T") {
                    this.showAccountProfile = false
                    this.showNewsletter = true
                } else {
                    this.showAccountProfile = false
                    this.showNewsletter = false
                }
            },
            async validateEmail () {
                this.isEmailUpdated = true
                this.accountType = null
                // console.log("inside validate validateEmail",this.email)
                // this.isEmailUpdated = true
                if (this.email) {
                    const result = await this.$store.dispatch("profile/validateEmail", this.email)
                    this.updateOptions(result)
                }
            },
            updateOptions (response) {
                if (response.accountType && response.accountType !== null) {
                    this.accountType = response.accountType
                    this.errorMessage = null
                } else {
                    this.accountType = null
                    if (response.errorMessage && response.errorMessage !== null){
                        this.errorMessage =  response.errorMessage
                    }
                }
                this.accountExists = this.accountType ? true : false
                this.emailValidated = this.errorMessage ? false : true
            },
            sendCodeAndVerifyEmail() {
              this.isUpdateNewsletter = true
              this.sendCode()
            },
            sendCode() {
                if (this.email) {
                    this.$bvModal.show("verification-modal")
                    axios.get(`${Config.api.url}/users/send/code/${this.email}`).then((response) => {
                        console.log(response)
                    }).catch(error => {
                        console.log(error)
                    })
                }
            },
            onSubmit(evt) {
                evt.preventDefault()
                var payload = {
                    code: this.verificationCode,
                    email: this.email
                }
                var self = this
                axios.get(`${Config.api.url}/users/verify/code`, {
                    params: payload
                }).then((response) => {
                    console.log(response)
                    if (response.data === true) {
                        self.$bvModal.hide("verification-modal")
                        self.$store.dispatch("profile/updateVerificationCode", self.verificationCode)
                        localStorage.setItem(Config.orcid.mailingListTokenCookie, self.verificationCode)
                        if (self.isUpdateNewsletter) {
                            self.$router.push(self.newsletterLink)
                        } else {
                            self.showError = false
                            self.newsSelected = "A"
                            self.showAccountProfile = true
                            self.showNewsletter = false
                        }
                    } else {
                        self.showError = true
                    }
                }).catch(error => {
                    self.showError = true
                })
            },
            resetModal() {
                this.verificationCode = ""
                if (this.showAccountProfile) {
                    document.getElementById("newsSelected").checked = true
                } else {
                    document.getElementById("newsSelected").checked = false
                }
            },
            showModal() {
                this.verificationCode = ""
            },
            async checkForOrcidRedirect() {
              const userInfoCookie = localStorage.getItem(Config.orcid.userInfoCookie)
              if (userInfoCookie) {
                const email = JSON.parse(userInfoCookie)?.people?.email
                if (email) {
                  this.orcidRedirect = true
                  this.email = email
                  await this.validateEmail()
                  this.accountType = "A"
                  this.showSelectedContent(this.accountType)
                }
              }
            }
        }
    }
</script>

<style scoped>
    .fa-sync-alt {
        /* color: #00BD70; */
    }
    .helpText {
        color: black !important;
    }
    .confirmBtn {
        /* background-color: #00BD70 !important; */
    }
</style>
