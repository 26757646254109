<template>
    <div style="max-width: 100%;">
      <!-- Government Website Banner -->
        <b-container>
            <div style="max-width: 1200px; width: 100%">
                <b-row class="mb-2 justify-content-center">
                <b-col>
                <div class="d-flex align-items-center">
                    <img
                        alt="U.S. flag"
                        class="usa-banner__header-flag me-2 w-3"
                        :src="armGovUrl + '/img/flag-icon.png'"
                    />
                    <span><b>An official website of the United States government</b></span>
                    <b-button
                        variant="link"
                        class="usa-banner__button ms-2"
                        aria-expanded="false"
                        @click="toggleBanner"
                    >
                        <span><b>Here's how you know</b></span>
                        <b-icon :icon="isBannerExpanded ? 'chevron-up' : 'chevron-down'" class="ms-1" />
                    </b-button>
                </div>
            </b-col>
        </b-row>
    </div>


        <b-collapse id="gov-banner-default" :visible="isBannerExpanded" class="pt-3 pb-4 bg-white">
          <b-row>
            <b-col sm="6" class="d-flex">
              <img alt="" class="icon" :src="armGovURL + '/img/icon-dot-gov.svg'" />
              <div class="disclaimer-text ms-3 pl-2">
                <p>
                  <b><strong>Official websites use .gov</strong><br />
                  A <strong>.gov</strong> website belongs to an official government
                  organization in the United <br />States.
                  </b>
                </p>
              </div>
            </b-col>
            <b-col sm="6" class="d-flex">
              <img alt="" class="icon" :src="armGovURL + '/img/icon-https.svg'" />
              <div class="disclaimer-text ms-3 pl-2">
                <p>
                  <b><strong>Secure .gov websites use HTTPS</strong><br />
                  A <strong>lock</strong> (<b-icon icon="lock-fill"/>) or <strong>https://</strong> means you've safely
                  connected to the .gov website. <br />Share sensitive information only on official,
                  secure websites.</b>
                </p>
              </div>
            </b-col>
          </b-row>
        </b-collapse>
      </b-container>

    <b-container fluid class="justify-content-center d-flex">
        <div style="max-width: 1200px; width: 100%;">
        <b-row class="mb-4 justify-content-between align-items-center flex-nowrap">
            <b-col cols="auto" class="d-flex align-items-center">
                <a :href="armGovUrl" title="ARM home page">
                    <img
                        alt="ARM Logo"
                        class="header-icon"
                        :src="armGovUrl + '/img/arm-logo.png'"
                    />
                </a>
            </b-col>
            <b-col cols="auto" class="d-flex align-items-center">
                <a href="http://science.energy.gov/" target="_blank" title="DOE's Office of Science">
                    <img
                        alt="DOE Logo"
                        class="header-icon"
                        src="https://armgov-wp.svcs.arm.gov/wp-content/uploads/doe-logo.png"
                        style="width:220px"
                    />
                </a>
            </b-col>
        </b-row>
        </div>
    </b-container>

    <!-- Main Navigation -->
    <b-container fluid class="nav-container">
        <div class="d-flex justify-content-center">
            <div>
                <b-row class="justify-content-between flex-nowrap">
                    <b-col lg="9" class="">
                        <b-navbar toggleable="lg" variant="light" class="bg-white flex-nowrap">
                        <b-navbar-nav class="flex-nowrap">
                          <div
                            @mouseover="showDropdown('data-dropdown')"
                          >
                            <b-nav-item-dropdown
                              ref="data-dropdown"
                              v-b-hover="(hovered) => hoverHandler(hovered, 'data-dropdown')"
                              class="pr-3 mx-lg-3 custom-nav-item"
                              no-caret
                            >
                                <template #button-content>
                                    Data
                                    <b-icon
                                      icon="chevron-down"
                                      font-scale=".8"
                                      class="ml-1 mb-1 text-weight-bold"
                                    />
                                </template>
                                <b-dropdown-item class="custom-nav-dropdown" href="https://adc.arm.gov/discovery/">Data Discovery</b-dropdown-item>
                                <b-dropdown-item class="custom-nav-dropdown" :href="armGovUrl + '/data/data-sources'">Data Sources</b-dropdown-item>
                                <b-dropdown-item class="custom-nav-dropdown" :href="armGovUrl + '/data/work-with-arm-data'">Work with ARM Data</b-dropdown-item>
                                <b-dropdown-item class="custom-nav-dropdown" :href="armGovUrl + '/data/science-data-products'">Science Data Products</b-dropdown-item>
                                <b-dropdown-item class="custom-nav-dropdown" :href="armGovUrl + '/data/data-quality-program'">Data Quality Program</b-dropdown-item>
                            </b-nav-item-dropdown>
                          </div>

                          <div
                            @mouseover="showDropdown('capabilities-dropdown')"
                          >
                            <b-nav-item-dropdown
                              ref="capabilities-dropdown"
                              v-b-hover="(hovered) => hoverHandler(hovered, 'capabilities-dropdown')"
                              class="pr-3 mx-lg-3
                              custom-nav-item"
                              no-caret
                              >
                                <template #button-content>
                                    Capabilities
                                    <b-icon
                                      icon="chevron-down"
                                      font-scale=".8"
                                      class="ml-1 mb-1 text-weight-bold"
                                    />
                                </template>
                                <b-dropdown-item class="custom-nav-dropdown" :href="armGovUrl + '/capabilities/observatories'">Atmospheric Observatories</b-dropdown-item>
                                <b-dropdown-item class="custom-nav-dropdown" :href="armGovUrl + '/capabilities/instruments'">Instruments</b-dropdown-item>
                                <b-dropdown-item class="custom-nav-dropdown" :href="armGovUrl + '/capabilities/modeling'">Modeling</b-dropdown-item>
                                <b-dropdown-item class="custom-nav-dropdown" :href="armGovUrl + '/capabilities/computing-resources'">Computing Resources</b-dropdown-item>
                            </b-nav-item-dropdown>
                          </div>
                        <div
                          @mouseover="showDropdown('research-dropdown')"
                        >
                          <b-nav-item-dropdown
                            ref="research-dropdown"
                            v-b-hover="(hovered) => hoverHandler(hovered, 'research-dropdown')"
                            class="pr-3 mx-lg-3 custom-nav-item"
                            no-caret>
                              <template #button-content>
                                Research
                                <b-icon
                                  icon="chevron-down"
                                  font-scale=".8"
                                  class="ml-1 mb-1 text-weight-bold"
                                />
                              </template>
                            <b-dropdown-item class="custom-nav-dropdown" :href="armGovUrl + '/research/campaigns'">Campaigns</b-dropdown-item>
                            <b-dropdown-item class="custom-nav-dropdown" :href="armGovUrl + '/research/publications'">Publications</b-dropdown-item>
                            <b-dropdown-item class="custom-nav-dropdown" :href="armGovUrl + '/research/highlights'">Research Highlights</b-dropdown-item>
                          </b-nav-item-dropdown>
                        </div>

                        <div
                          @mouseover="showDropdown('user-program-dropdown')"
                        >
                          <b-nav-item-dropdown
                            ref="user-program-dropdown"
                            v-b-hover="(hovered) => hoverHandler(hovered, 'user-program-dropdown')"
                            class="pr-3 mx-lg-3 custom-nav-item"
                            no-caret>
                              <template #button-content>
                                User Program
                                <b-icon
                                  icon="chevron-down"
                                  font-scale=".8"
                                  class="ml-1 mb-1 text-weight-bold"
                                />
                              </template>
                              <b-dropdown-item class="custom-nav-dropdown" :href="armGovUrl + '/user-program/pier-plan'">Pier Plan</b-dropdown-item>
                              <b-dropdown-item class="custom-nav-dropdown" :href="armGovUrl + '/user-program/constituent-groups'">Constituent Groups</b-dropdown-item>
                              <b-dropdown-item class="custom-nav-dropdown" :href="armGovUrl + '/user-program/stats'">User Statistics</b-dropdown-item>
                          </b-nav-item-dropdown>
                        </div>


                        <div
                          @mouseover="showDropdown('news-events-dropdown')"
                        >
                          <b-nav-item-dropdown
                            ref = "news-events-dropdown"
                            v-b-hover="(hovered) => hoverHandler(hovered, 'news-events-dropdown')"
                            class="pr-3 mx-lg-3 custom-nav-item" no-caret>
                            <template #button-content>
                                News & Events
                                <b-icon
                                  icon="chevron-down"
                                  font-scale=".8"
                                  class="ml-1 mb-1 text-weight-bold"
                                />
                            </template>
                            <b-dropdown-item class="custom-nav-dropdown" :href="armGovUrl + '/news-events/data-announcements'">Data Announcements</b-dropdown-item>
                            <b-dropdown-item class="custom-nav-dropdown" :href="armGovUrl + '/news-events/features'">Features</b-dropdown-item>
                            <b-dropdown-item class="custom-nav-dropdown" :href="armGovUrl + '/news-events/news'">News</b-dropdown-item>
                            <b-dropdown-item class="custom-nav-dropdown" :href="armGovUrl + '/news-events/events'">Events & Meetings</b-dropdown-item>
                            <b-dropdown-item class="custom-nav-dropdown" :href="armGovUrl + '/news-events/field-notes'">Field Notes</b-dropdown-item>
                          </b-nav-item-dropdown>
                        </div>

                        <div
                          @mouseover="showDropdown('about-dropdown')"
                        >
                          <b-nav-item-dropdown
                            ref="about-dropdown"
                            v-b-hover="(hovered) => hoverHandler(hovered, 'about-dropdown')"
                            class="mx-lg-3
                            custom-nav-item"
                            no-caret>
                            <template #button-content>
                              About
                              <b-icon
                                icon="chevron-down"
                                font-scale=".8"
                                class="ml-1 mb-1 text-weight-bold"
                              />
                            </template>
                            <b-dropdown-item class="custom-nav-dropdown" :href="armGovUrl + '/about/leadership-organization'">Leadership & Organization</b-dropdown-item>
                            <b-dropdown-item class="custom-nav-dropdown" :href="armGovUrl + '/about/collaborations'">Collaborations</b-dropdown-item>
                            <b-dropdown-item class="custom-nav-dropdown" :href="armGovUrl + '/about/future-directions'">Future Directions</b-dropdown-item>
                            <b-dropdown-item class="custom-nav-dropdown" :href="armGovUrl + '/about/history'">History</b-dropdown-item>
                          </b-nav-item-dropdown>
                        </div>
                </b-navbar-nav>
            </b-navbar>
                </b-col>
                <b-col lg="3" class="d-flex flex-nowrap" align-v="center">
                    <b-form-input
                        v-model="searchQuery"
                        class="header-search button-text-grey rounded-0"
                        placeholder="Search ARM.gov ..."
                        aria-label="Search"
                        style="width: 150px; height: 2rem"
                        @keyup.enter="applySearch"
                    />
                    <b-button :squared="true" class="ml-0 border rounded p-2 header-button-grey text-nowrap align-items-center d-flex justify-content-center" @click="applySearch">
                        <b-icon icon="search" font-scale="2.5"/>
                    </b-button>

                    <b-button
                        id="toggle-btn"
                        :squared="true"
                        variant="outline-secondary"
                        class="align-items-center d-flex justify-content-center ml-2 border rounded p-2 header-button-navy text-nowrap"
                        @click="toggleModal"
                    >
                        <span class="button-text p-2">Contact Us</span>
                        <b-icon icon="chat-square" font-scale="2.5"/>
                    </b-button>
                </b-col>
            </b-row>
        </div>
      </div>
      </b-container>

        <!-- Contact Us Modal -->
        <div>
            <b-modal ref="my-modal" class="contact-modal" centered hide-footer title="Contact Us">
                <ContactUsModal />
            </b-modal>
        </div>
    </div>
  </template>

  <script>
    import { BContainer, BRow, BCol, BButton, BCollapse, BNavbar, BNavbarNav, BNavItemDropdown, BDropdownItem, BFormInput, BIcon, BIconChatFill } from 'bootstrap-vue';
    // import ContactUsModal from '@/components/ContactUsModal.vue'
    // import Vue from 'vue'
    import ContactUsModal from './ContactUsModal.vue';
    import Config from '../config'


  export default {
    components: {
      BCollapse,
      BNavbar,
      BNavbarNav,
      BNavItemDropdown,
      BDropdownItem,
      BFormInput,
      BIcon,
      ContactUsModal,
    },
    data() {
      return {
        isBannerExpanded: false,
        searchQuery: '',
        hoverTimeouts: {},
        currentHoverStates: {},
      };
    },
    computed: {
      flagIcon(){
        return Config.flagIcon
      },
      armGovUrl(){
        return Config.armgov.url
      },
    },
    methods: {
      toggleBanner() {
        this.isBannerExpanded = !this.isBannerExpanded;
      },
      applySearch() {
        window.location.href = `${Config.armgov.url}/search?q=${encodeURIComponent(this.searchQuery)}`;
      },
      showModal() {
        this.$refs['my-modal'].show()
      },
      hideModal() {
        this.$refs['my-modal'].hide()
      },
      toggleModal() {
        // We pass the ID of the button that we want to return focus to
        // when the modal has hidden
        this.$refs['my-modal'].toggle('#toggle-btn')
      },
      showDropdown(ref) {
          this.$refs[ref].visible = true
      },
      hoverHandler(isHovered, dropdownRef) {
        // Clear any existing timeout for this dropdown
        if (this.hoverTimeouts[dropdownRef]) {
          clearTimeout(this.hoverTimeouts[dropdownRef]);
        }

        // Update current hover state immediately
        this.currentHoverStates[dropdownRef] = isHovered;

        // Set a new timeout
        this.hoverTimeouts[dropdownRef] = setTimeout(() => {
        // Check if the hover state is still the same after the delay
        if (this.currentHoverStates[dropdownRef] === isHovered) {
          this.$refs[dropdownRef].visible = isHovered;
        }
        }, 300);
      }
    },
  };
  </script>

  <style scoped>
  @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100..900;1,100..900&display=swap');

  .usa-banner__header-flag {
    max-height: 16px;
  }

  .usa-banner__button {
    color: rgb(32, 111, 193) !important;
    font-family: "Roboto Light", serif !important;
    font-size: small;
  }

  .usa-banner__button-text {
    margin-left: 8px;
  }

  .header-icon{
    max-width: 220px;
    display: flex;
    align-items: center;
    max-height: 80px;
  }

  img {
    padding-right: 0.5rem;
  }

  .header-button-navy, .header-button-navy:hover {
    max-height: 2rem;
    padding: 0 0.5rem;
    background-color: white !important;
    color: rgb(24, 65, 116);
  }

  .header-button-grey {
    max-height: 2rem;
    padding: 0 0.5rem;
    background-color: white !important;
    color: black !important;
  }

  .custom-nav-item ::v-deep .nav-link {
    font-family: "Roboto Condensed", sans-serif;
    color: black !important;
    /* text-transform: uppercase; */
    font-weight: 400;
    font-size: medium;
    padding-top: -2em;
  }

  .nav-container {
    border-bottom: 0.5px solid;
    border-color: #00DD82 !important;
  }

  .icon {
    padding-right: 0.5rem;
    max-height: 40px;
  }

  .custom-nav-dropdown {
    font-family: "Roboto", serif;
    color: rgb(32, 111, 193) !important;
    /* text-transform: uppercase; */
    font-weight: 400;
    font-size: small;
    letter-spacing: 0;
  }

  .button-text {
    font-size: small;
    letter-spacing: 0;
  }

  .button-text-grey {
    font-size: 0.75rem;
    letter-spacing: 0;
  }

  .text-navy {
    color: rgb(24, 65, 116) !important;
  }

  .disclaimer-text {
    font-family: "Roboto", serif !important;
    letter-spacing: 0;
    font-size: small;
  }

  ::v-deep .modal-title {
    font-family: "Roboto Condensed", serif;
    font-size: 2.25rem;
    line-height: 2.5rem;
    color: #004893;
    border: none;
    font-weight: 450;
  }

  ::v-deep .modal-header {
    border: none;
    margin-bottom: -20px;
  }

  ::v-deep .modal-content {
    width: 600px;
  }
  </style>

