<template>
    <b-container fluid :class="{ 'paddingBtn': !isEdit }">
        <TagHeader :title="title" :buttons="buttons"></TagHeader>
        <ProfileContent v-if="!isEdit"/>
        <AccountProfileContent v-if="isEdit" />
    </b-container>
</template>

<script>
    import TagHeader from "@/components/TagHeader"
    import ProfileContent from "@/components/ProfileContent"
    import AccountProfileContent from "@/components/AccountProfileContent"
    import { mapState } from "vuex"
    import Config from "../config"

    export default {
        name: "Profile",
        components: {
            TagHeader,
            ProfileContent,
            AccountProfileContent,
        },
        data() {
            return {
                title: "",
                breadCrumbTitle: "Connect with ARM > Account Management",
                buttonGroup: [
                    {name:"account", label:"Update Account Details"},
                    {name:"update", label:"Subscriptions"},
                    {name:"logout", label:"Logout"}
                ],
                buttons: [],
                isEdit: false
            }
        },
        computed: {
            ...mapState("auth",["details"]),
        },
        watch: {
            $route: "init"
        },
        mounted() {
          this.init()
        },
        methods: {
          async init() {
            const orcidAccessToken = this.$route?.query?.access_token?.toString()
            if (orcidAccessToken) {
              localStorage.setItem(Config.orcid.accessTokenCookie, orcidAccessToken)
              const userInfo = localStorage.getItem(Config.orcid.userInfoCookie)
              if (userInfo) {
                const personId = JSON.parse(userInfo)?.people?.personId
                const userName = JSON.parse(userInfo)?.people?.userName
                if (personId && userName) {
                  const statusCode = JSON.parse(userInfo)?.people?.statusCode
                  if (statusCode === "M") {
                    await this.$router.replace({
                      name: "profile",
                    })
                  } else {
                    await this.$router.replace({
                      name: "edit",
                      params: {
                        id: userName,
                      },
                    })
                  }
                } else {
                  await this.$router.replace({query: null})
                }
              }
            }

            if (this.$route.params.id) {
                this.buttons = this.buttonGroup
                this.title = "Personal Profile"
                this.isEdit = true
            } else {
                this.buttons = []
                this.title = "Create an Account"
                this.isEdit = false
            }
          }
        }
    }
</script>

<style scoped>
    .paddingBtn {
        padding-bottom: 150px !important;
    }
</style>
