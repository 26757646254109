<template>
    <div id="app">
        <div v-if="maintenanceMessage.length > 0">
            <b-alert show dismissible variant="danger">
                {{maintenanceMessage}}
            </b-alert>
        </div>
        <ArmHeader />
        <transition name="slide" mode="out-in">
            <router-view id="content" class="container appcontainer"/>
        </transition>
        <ArmFooter />

    </div>
</template>

<script>
    import Config from "./config";
    import axios from "axios";
    import ArmFooter from "@/components/ArmFooter"
    import ArmHeader from "@/components/ArmHeader"
    // Handle redirects for archive URLs
    let location = window.location.href
    location = location.replace("www.archive.arm.gov", "adc.arm.gov")
    location = location.replace("archive.arm.gov", "adc.arm.gov")

    if (location !== window.location.href) {
        window.location.href = location
    }
    export default {
        name: 'App',
        components: {
            ArmHeader,
            ArmFooter
        },
        data() {
            return {
                maintenanceMessage: ""
            }
        },
        mounted() {
            this.checkMaintenanceMessage()
        },
        methods: {
            goHome() {
                this.$router.push({name: "dashboard"})
            },
            checkMaintenanceMessage() {
                axios.get(`${Config.archiveServices.url}/MaintenanceCheck?app=arm.userregis`).then((response) => {
                    if (response.data.message && response.data.message != "") {
                        this.maintenanceMessage = response.data.message
                    } else {
                        this.maintenanceMessage = ""
                    }
                }).catch(error => {
                    this.maintenanceMessage = ""
                })
            }
        }
    }
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');
    .navbar {
        align-items: inherit !important;
    }

    input:focus,
    select:focus,
    textarea:focus,
    button:focus {
        outline: 2px solid rgb(32 111 193 / var(--tw-text-opacity, 1)) !important;
        /* border: 2px solid rgb(32 111 193 / var(--tw-text-opacity, 1)) !important; */
        box-shadow: none !important;
    }

    input,
    select,
    textarea {
        border: 1px solid #ced4da !important;
    }

    .nav-link {
        padding: inherit !important;
    }

    .slide-enter-active,
    .slide-leave-active {
        transition: all .3s ease;
    }

    .slide-leave-to {
        transform: translateX(-10%);
        opacity: 0;
    }

    .slide-enter {
        transform: translateX(10%);
        opacity: 0;
    }

    /* #app {
        padding-top: 32px;
        width: 100%;
        margin: auto;
        padding-bottom: .5em;
    } */

    #app .content {
        font-family: "Roboto", sans-serif;
        font-weight: 400;
        padding-top: 32px;
        width: 100%;
        margin: auto;
        padding-bottom: .5em;
    }



    html body {
        font-family: "Roboto", serif;
        font-weight: 300;
        color: #0d1b2a;
        font-size: 11pt;
        background-color: white !important;
        width: 100%;
    }

    .btn {
        font-family: "Roboto", serif;
        font-weight: 500;
        font-size: 6px;
        letter-spacing: 1px;
        margin: 0 3px;
        font-weight: 400;
        text-decoration: none;
        line-height: 1.4;
    }

    .arm-alert {
        color: black !important;
        background-color: rgb(230, 241, 255) !important;
        padding: 1.4em !important;
        border: 1px solid #ced4da !important;
        border-radius: .25rem !important;
    }   

    .btn-link {
        color: white !important;
        text-decoration: none !important;
    }

    .title-box {
        margin: 0;
        /* border-left: 7px solid #00BD70; */
        display: table;
        width: 100%;
        position: relative;
    }

    .arm-radios {
        font-family: "Roboto Condensed";
        font-weight: 450;
        background-color: rgb(230, 241, 255);
        padding: 1.4em;
        border: 1px solid #ced4da;
        border-radius: .25rem;
    }

    .custom-control-label::before {
        /* border-radius: 0 !important; */
    }


    .title-box .title {
        padding-left: .9375rem;
        /*padding-right: 50px;*/
        display: table-cell;
        vertical-align: middle;
    }

    .title-box .title h1 {
        margin: 0;
        white-space: normal;
        color: rgb(0 72 147 / var(--tw-text-opacity, 1));
        font-weight: 400;
    }

    .title-box .title .btn-toolbar {
        position: absolute;
        bottom: 0;
        right: 0;
    }

    .btn-toolbar > .btn {
        margin-left: 0 !important;
        margin-right: 1em !important;
        margin-top: 0.8em !important;
    }

    .title-box .title .breadcrumb-title {
        font-family: "Roboto Condensed", serif;
        /* text-transform: uppercase; */
        /* font-weight: bold; */
        font-size: 12pt;
        white-space: normal;
        color: #184174;
    }

    .title-box .title .breadcrumb-title a {
        color: #184174;
        text-decoration: none;
        position: relative;
    }

    .title-box .title .breadcrumb-title a:hover {
        color: #184174;
        text-decoration: underline;
    }

    .title-box .title h1 {
        font-family: "Roboto Condensed", serif;
        /* text-transform: uppercase; */
        /* font-weight: 700 !important; */
        font-size: 30pt !important;
    }

    .section h2 {
        position: relative;
        display: inline-block;
        color: rgb(0 72 147 / var(--tw-text-opacity, 1));
        border-top: 2px solid rgb(32 111 193 / var(--tw-text-opacity, 1)) !important;;
        padding-top: 0.3em;
        margin-bottom: 11px;
        font-size: 17pt !important;
        line-height: 1.2;
        font-family: "Roboto Condensed", serif;
        font-weight: 450 !important;
        /* text-transform: uppercase; */
        /* font-weight: 700 !important; */
    }

    h3 {
        font-family: "Roboto Condensed", serif;
        /* text-transform: uppercase; */
        /* font-weight: 700 !important; */
        font-size: 13pt !important;
        line-height: 1.1 !important;
    }

    .section .content {
        line-height: 1.3;
    }

    .section .content h2 {
        margin-top: 30px;
    }

    .section {
        margin-bottom: 25px;
        padding-left: 0;
        font-weight: 2500;
    }

    .section.bordered {
        border-top: 1px solid lightgray;
        /* border-left: 7px solid #0D1B2A; */
    }

    .thick {
        font-weight: bold;
    }

    .invalid, .arm-required {
        color: red;
    }

    .btn-arm-primary {
        color: #184174 !important;
        border-radius: 0 !important;
        border: 1px solid #184174 !important;
        background-color: white !important;
        text-transform: uppercase;
        font-size: 0.875rem !important;
    }

    .btn-arm-primary.disabled {
        color: grey !important;
        border: 1px solid grey !important;
    }

    .valid {
        color: green;
    }

    .list-group-item:hover {
        background-color: gray !important;
        border-color: gray !important;
        color: white !important;
    }

    a {
        color: rgb(32 111 193 / var(--tw-text-opacity, 1)) !important;
        text-decoration: none !important;
    }

    #autosuggest * {
        border-radius: 0 !important;
    }

    /* Makes bottom corners not rounded when suggestions visible */
    #autosuggest input.autosuggest__input--open,
    #autosuggest input.autosuggest__input1--open {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .appcontainer {
        padding: 3rem 0rem;
    };

    /* Restricts results to input width */
    .autosuggest__results-container {
        position: relative;
        width: 100%;
    }

    /* General formatting of results */
    .autosuggest__results {
        font-weight: 300;
        margin: 0;
        position: absolute;
        z-index: 10000001;
        width: 100%;
        border: 1px solid #e0e0e0;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        background: white;
        padding: 0px 10px 5px 10px;
        max-height: 400px;
        overflow-y: scroll;
    }

    /* Clean up for results list */
    .autosuggest__results ul {
        list-style: none;
        padding-left: 0;
        margin: 0;
        padding-bottom: 5px;
        padding-top: 5px;
    }

    /* Changes cursor to pointer when over suggestion */
    .autosuggest__results .autosuggest__results-item {
        cursor: pointer;
        padding: 3px 3px 3px 13px;
    }

    /* Removes divider from top group and search text */
    #autosuggest ul:nth-child(1) > .autosuggest__results-before {
        border-top: none;
    }

    /* Section Header */
    .autosuggest__results li.autosuggest__results-before {
        color: gray;
        font-size: 11px;
        margin-left: 0;
        padding: 15px 13px 5px;
        border-top: 1px solid lightgray;
    }

    /* Hover Style */
    .autosuggest__results .autosuggest__results-item:active,
    .autosuggest__results .autosuggest__results-item:hover,
    .autosuggest__results .autosuggest__results-item:focus,
    .autosuggest__results .autosuggest__results_item.autosuggest__results-item-highlighted {
        background-color: #f6f6f6;
    }

    .greenButton {
        color: white !important;
        /* background-color: #00BD70 !important; */
        /* border: 1px solid #00BD70 !important; */
    }

    .anchorlink {
        color: #007bff !important;
        background-color: transparent !important;
        cursor: pointer !important;
    }

    .custom-control + .custom-control {
        margin-left: 0rem !important;
    }
</style>
