<template>
    <div>
        <div class="section0">
            <div class="section bordered">
                <div class="content" data-section-name="forgotOptions">
                    <b-row>
                        <b-col lg="6">
                            <p>
                                Are you here to request a <strong>Username</strong>?
                            </p>
                            <small>Please check 'yes' for username or 'no' for password.</small>
                        </b-col>
                        <b-col lg="6" class="mt-2">
                            <b-form-radio-group v-model="nameRequest" class="arm-radios" :options="passwordOptns"></b-form-radio-group>
                        </b-col>
                    </b-row>
                </div>
            </div>
        </div>
        <div v-if="nameRequest" class="section1">
            <div class="section bordered">
                <h2>Request Username</h2>
                <div class="content" data-section-name="usernameRequest">
                    <b-row>
                        <b-col>
                            If you don't know or forgot your username, please enter your email address below. We will send your username to you. Please make sure this email address matches the email address in our database.
                        </b-col>
                    </b-row>
                    <b-row class="mt-3">
                        <b-col lg="6">
                            <label class="thick">Email address</label>
                        </b-col>
                        <b-col lg="6">
                            <b-form-input
                              v-model="email"
                              class="rounded-0"
                              placeholder="Please enter your email address."
                            />
                            <b-button
                              class="mt-3"
                              block
                              variant="arm-primary"
                              :disabled="!email || disableButton"
                              @click="requestUsername"
                            >
                              Request
                            </b-button>
                        </b-col>
                    </b-row>
                </div>
            </div>
        </div>
        <div v-else class="section1">
            <div class="section bordered">
                <h2>Request Password</h2>
                <div class="content" data-section-name="passwordRequest">
                    <b-row>
                        <b-col>
                            If you forgot your password or if you need one created, please enter your username below. We will generate a new password and send it to you.
                        </b-col>
                    </b-row>
                    <b-row class="mt-3">
                        <b-col lg="6">
                            <label class="thick">Username</label>
                        </b-col>
                        <b-col lg="6">
                            <b-form-input v-model="username" class="rounded-0" placeholder="Please enter your username." ></b-form-input>
                            <b-button class="mt-3" block variant="arm-primary" :disabled="!username || disableButton" @click="requestPassword">Request</b-button>
                        </b-col>
                    </b-row>
                </div>
            </div>
        </div>
        <b-row v-if="showAlert" class="mt-4 justify-content-md-center">
            <b-col lg="8">
              <b-alert v-if="response && response != ''" show variant="danger">You currently only have an ARM Limited User account. For access to ARM data and applications, <strong>please click on this <router-link to="new">link</router-link> and select 'Create a Full Account'</strong> to upgrade your account and establish login
                credentials.</b-alert>
              <span v-else>
                  <b-alert v-if="nameRequest" show>If a username is associated with this address, an email will be sent containing the username.</b-alert>
                  <b-alert v-else show>If the username is on file, an email will be sent to the email address on the account.</b-alert>
              </span>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import Config from "../config";
    import axios from "axios";
    export default {
        name: "ForgotPasswordContent",
        data() {
            return {
                passwordOptns: [
                    { text: "Yes", value: true},
                    { text: "No", value: false}
                ],
                nameRequest: true,
                email: "",
                username: "",
                showAlert: false,
                disableButton: false,
                response: "",
            }
        },
        watch: {
          $route: "init",
          nameRequest() {
            this.showAlert = false
            this.disableButton = false
          }
        },
        mounted() {
            this.init()
        },
        methods: {
            init() {
                if (this.$route.params.id) {
                    this.username = this.$route.params.id
                }
            },
            updateInput(payload) {
                this[payload.label] = payload.value
            },
            requestPassword(ev) {
                this.response = ""
                this.showAlert = false
                this.disableButton = false
                ev.preventDefault()
                var self = this
                axios({
                    method: "post",
                    url: `${Config.api.url}/users/forgot/password/${this.username}`,
                }).then((response) => {
                    self.response = response.data
                    self.showAlert = true
                    self.disableButton = true
                }).catch((error) => {
                    self.disableButton = false
                })

            },
            requestUsername(ev) {
                this.response = ""
                this.showAlert = false
                this.disableButton = false
                ev.preventDefault()
                var self = this
                axios({
                    method: "post",
                    url: `${Config.api.url}/users/forgot/username/${this.email}`,
                }).then((response) => {
                    self.response = response.data
                    self.showAlert = true
                    self.disableButton = true
                }).catch((error) => {
                    self.disableButton = false
                })
            }
        }
    }
</script>

<style scoped>

</style>
