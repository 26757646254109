<template>
    <b-container fluid>
        <TagHeader :title="title" :buttons="buttons"></TagHeader>
        <ForgotPasswordContent />
    </b-container>
</template>

<script>
    import TagHeader from "@/components/TagHeader"
    import ForgotPasswordContent from "@/components/ForgotPasswordContent"
    export default {
        name: "ForgotPassword",
        components: {
            TagHeader,
            ForgotPasswordContent
        },
        data() {
            return {
                title: "ARM People Account Request",
                buttons: [
                    {name:"login", label:"Login"}
                ]
            }
        },
    }
</script>

<style scoped>

</style>