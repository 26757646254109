<template>
    <div class="section4">
        <div class="section bordered">
            <h2>Select User Type</h2>
            <div class="content" data-section-name="userTypeinfo">
                <b-row>
                    <b-col lg="12">
                        <span>
                            It is important for ARM to be able to provide information to its funding sponsor,
                            the DOE Office of Science, on the use of ARM data for scientific research.
                            Please select the appropriate user type:
                        </span>

                        <b-form-group>
                            <b-form-radio-group v-model="people.userTypeCode" class="arm-radios">
                                <b-form-radio
                                    value="R"
                                    class="mt-3"
                                >
                                    <span class="font-weight-bold">Research User:</span>
                                    If you are using ARM data as part of a peer-reviewed research
                                    project or a graduate thesis or if you are a principal investigator or
                                    co-investigator on an ARM field campaign, please select “Research User” and
                                    provide information about your project.
                                    <br />
                                    <span class="font-weight-bold">Note:</span>
                                    Research users include ARM infrastructure staff who expect to publish
                                    peer-reviewed papers with ARM data, who have externally funded research projects,
                                    or who are named investigators on ARM field campaigns.
                                </b-form-radio>

                                <b-form-radio
                                    value="E"
                                    class="mt-3"
                                >
                                    <span class="font-weight-bold">Educational/Class Project:</span>
                                    If you are accessing ARM data for educational purposes
                                    or for a class project assigned by your teacher/professor, please select
                                    “Educational/Class Project” and briefly describe your educational project.
                                    If you are accessing ARM data for your thesis research, please see above.
                                </b-form-radio>

                                <b-form-radio
                                    value="O"
                                    class="mt-3"
                                >
                                    <span class="font-weight-bold">Other:</span>
                                    If you are accessing ARM data solely for non-research or educational purposes,
                                    please select the “Other” type and briefly describe why you are accessing ARM data.
                                </b-form-radio>

                                <b-form-invalid-feedback
                                    :state="validateState('userTypeCode')"
                                    class="thick"
                                >
                                    Please select if you are a research user or not
                                </b-form-invalid-feedback>
                            </b-form-radio-group>
                        </b-form-group>
                    </b-col>
                </b-row>
                <FormTextArea
                  class="mt-3"
                  label="If not a research user, please tell us briefly why you are accessing ARM data."
                  :required="isAccessStatementReq"
                  name="accessStatement"
                  :value="people.accessStatement"
                  @textAreaUpdated="textAreaUpdated"
                  :state="validateAssessment"
                  aria-describedby="feedback"
                  :message="`If not a research user, please tell us why you are accessing ARM data. Access statement must not include invalid characters such as ${restrictedCharacters}`"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import FormTextArea from "@/components/FormTextArea"
    import { validationMixin } from "vuelidate";
    import {required, requiredIf} from "vuelidate/lib/validators";
    import Config from "@/config"

    const zeroOrMoreValidator = Config.validations.zeroOrMoreValidator
    const oneOrMoreValidator = Config.validations.oneOrMoreValidator

    export default {
        name: "UserTypeTemplate",
        mixins: [validationMixin],
        components: {
            FormTextArea
        },
        props: {
            people: {
                type: Object,
                default:  function() {
                    return {
                        userTypeCode: "R",
                        accessStatement: ""
                    }
                }
            },
            isRecordSubmitted: {default: false, type: Boolean}
        },
        data() {
            return {
                max: false,
                userTypeOptions: [
                    {
                        text: "Research User",
                        value: "R",
                    },
                    {
                        text: "Educational/Class Project",
                        value: "E",
                    },
                    {
                        text: "Other (non-research role)",
                        value: "O",
                    },
                ],
                isAccessStatementReq: false
            }
        },
        validations: {
            people: {
                userTypeCode: {
                    required,
                    oneOrMoreValidator,
                },
                accessStatement: {
                  zeroOrMoreValidator,
                  required: requiredIf(function() {
                    return this.people.userTypeCode !== "R"
                  }),
                }
            }
        },
        computed: {
          restrictedCharacters() {
            return Config.restrictedCharacters
          },
          validateAssessment() {
            const valid = this.validateState("accessStatement")
            return this.people.userTypeCode === "R" ?
              valid :
              this.people.accessStatement !== "" && this.people.accessStatement !== undefined && this.people.accessStatement !== null && valid
          },
          validationObject() {
            return JSON.stringify(this.$v.people)
          },
        },
        watch: {
            isRecordSubmitted() {
                this.$v.people.$touch();
            },
            "people.userTypeCode": {
              handler(newVal, oldVal) {
                return newVal === "R" ?
                    this.isAccessStatementReq = false :
                    this.isAccessStatementReq = true
              }
            },
            validationObject: {
              handler(newVal, oldVal) {
                const a = JSON.parse(newVal)
                const b = JSON.parse(oldVal)
                Object.keys(a.$params).forEach(key => {
                  if (a[key].$model !== b[key].$model) {
                    this.$v.people[key].$touch()
                    this.$emit("userTypeValid", !this.$v.people.$invalid)
                  }
                })
              },
              deep: true,
            }
        },
        methods: {
            validateState(name) {
                const {$dirty, $error} = this.$v.people[name];
                return $dirty ? !$error : null;
            },
            textAreaUpdated(payload) {
                this.people[payload.label] = payload.value
                // console.log(this.accessStatement)
            },
            showContent(e){
                e.preventDefault();
                this.max = true
            },
            hideContent(e){
                e.preventDefault();
                this.max = false
            }
            // fieldUpdate(name) {
            //     this.$emit('fieldUpdate',{
            //         label: this.name,
            //         value: val
            //     })
            // }

        }
    }
</script>

<style scoped>
    .accessInvalid {
        margin-top: 0.25rem;
        font-size: 80%;
        color: #dc3545;
    }
</style>