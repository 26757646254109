<template>
  <b-modal
    id="orcidmodal-modal"
    centered
    no-fade
    no-close-on-backdrop
    no-close-on-esc
  >
    <template #modal-header>
      <div class="header title">
        ORCID Sign In Successful
      </div>
    </template>
    You have granted the ARM User Registration tool access to your ORCID record.
    Once you submit the form, your ORCID record will be linked to your ARM account.
    <br />
    <br />
    <b-row>
      <b-col cols="auto">
        <b>
          ORCID record
        </b>
      </b-col>
      <b-col class="text-right">
        <OrcidLink :orcid="orcid" />
      </b-col>
    </b-row>
    <br />
    <b>
      You must submit your changes by clicking "Submit" at the bottom of the ARM User Registration form to complete the linking process.
    </b>
    <hr />
    <div class="title">
      Use information from ORCID record?
    </div>
    You can use the information from your ORCID record to automatically populate your account information in the ARM User Registration form.
    <b>
      Be aware that if you choose "Overwrite" and submit the form, information from your ORCID record will overwrite any
      existing data in your ARM account where appropriate.
    </b>
    To use information from your ORCID record, click "Overwrite".
    Otherwise, click "Keep existing" if you wish to retain the data currently in the form.
    <template #modal-footer>
      <div class="w-100 text-right">
        <b-button
          variant="secondary"
          @click="$bvModal.hide('orcidmodal-modal')"
        >
          Keep existing
        </b-button>
        <b-button
          variant="success"
          @click="overwrite"
        >
          Overwrite
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import OrcidLink from "@/components/OrcidLink"

export default {
  components: {
    OrcidLink,
  },
  props: {
    orcidInfo: {required: false, default: ""},
  },
  computed: {
    orcid() {
      return this.orcidInfo?.["orcid-identifier"]?.path
    },
    firstName() {
      return this.orcidInfo?.person?.name?.["given-names"]?.value
    },
    lastName() {
      return this.orcidInfo?.person?.name?.["family-name"]?.value
    },
    fullName() {
      if (this.firstName && this.lastName) {
        return `${this.firstName} ${this.lastName}`
      } else if (this.firstName) {
        return this.firstName
      } else if (this.lastName) {
        return this.lastName
      } else {
        return null
      }
    },
  },
  methods: {
    overwrite() {
      this.$emit("overwrite")
      this.$bvModal.hide("orcidmodal-modal")
    }
  }
}
</script>

<style scoped>
.header {
  font-size: 1.2em;
}

.title {
  font-family: Oswald, Tahoma, sans-serif;
  /* text-transform: uppercase; */
  /* font-weight: 700 !important; */
}
</style>
