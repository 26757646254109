<template>
    <footer>
      <!-- Dark bar -->
        <b-container fluid class="bg-darknavy px-2 py-2">
            <div class="d-flex justify-content-center">
                <div style="max-width:1200px; width: 100%">
                    <b-row class="justify-content-between align-items-center">
                        <b-col cols="12" lg="auto" class="d-flex align-items-center">
                            <b-img :src="ArmLogoReverse" alt="ARM Logo" width="170" class="mr-3"></b-img>
                            <div>
                                <h6 class="text-white mb-2">Follow Us:</h6>
                                <b-row class="no-gutters">
                                    <b-col v-for="social in socialLinks" :key="social.name" cols="auto" class="pr-2">
                                        <b-link :href="social.url" :title="social.name">
                                            <b-img :src="social.logoSrc" :alt="`${social.name} logo`" width="25"></b-img>
                                        </b-link>
                                    </b-col>
                                </b-row>
                            </div>
                        </b-col>
                        <div class="mt-3 mt-lg-0 flex-container">
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="px-3 py-2">
                                    <h4 class="text-white-heading">Keep up with the <i>Atmospheric Observer</i></h4>
                                    <p class="text-white-content text-wrap"><strong>Updates on ARM news, events, and opportunities<br/> delivered to your inbox</strong></p>
                                </div>
                                <a href="https://adc.arm.gov/armuserreg/#/login" class="mt-2 text-uppercase text-small border rounded-sm py-2 px-3 whitespace-nowrap bg-transparent text-white border-white">
                                    <span class="button-text pr-1"><strong>Subscribe Now</strong></span>
                                    <b-icon icon="caret-right-fill" class="icon" font-scale="0.8"></b-icon>
                                </a>
                            </div>
                        </div>
                    </b-row>
                </div>
            </div>
        </b-container>

      <!-- Nav section -->
        <b-container fluid class="bg-lightblue text-darknavy py-4">
            <div class="d-flex justify-content-center">
                <div style="max-width:1200px; width: 100%">
                    <b-row>
                        <b-col lg="4" class="mr-6 rounded-0 px-4 py-4">
                            <b-card class="w-90 h-100 bg-lightblue border-navy rounded-0">
                                <h5 class="text-uppercase text-navy-header">ARM User Profile</h5>
                                <p class="text-navy"><strong>ARM welcomes users from all institutions and nations. A free ARM user account is needed to access ARM data.</strong></p>
                                <div class="mb-2">
                                    <b-row class="no-gutters">
                                        <b-col cols="auto" class="mr-2">
                                            <a href="https://adc.arm.gov/armuserreg/#/new" class="mr-2 text-uppercase text-small border rounded-sm px-3 py-2 whitespace-nowrap bg-white text-navy border-navy">
                                                <span class="button-text pr-1"><strong>Register</strong></span>
                                                <b-icon icon="caret-right-fill" font-scale="0.8"></b-icon>
                                            </a>
                                        </b-col>
                                        <b-col cols="auto" class="mr-2">
                                            <a href="https://adc.arm.gov/armuserreg/#/login" class="mr-2 text-uppercase text-small border rounded-sm px-3 py-2 whitespace-nowrap bg-white text-navy border-navy">
                                                <span class="button-text pr-1"><strong>Login</strong></span>
                                                <b-icon icon="caret-right-fill" font-scale="0.8"></b-icon>
                                            </a>
                                        </b-col>


                                    </b-row>
                                </div>
                            </b-card>
                        </b-col>
                        <b-col lg="8" class="mr-0 mt-6 rounded-0 py-4 lg:ml-6">
                            <b-row>
                                <b-col v-for="(section, index) in navSections" :key="index" class="mb-4">
                                    <h4 class="text-uppercase text-navy-header border-top border-armgreen pt-2">{{ section.title }}</h4>
                                    <b-nav vertical>
                                        <ul v-for="link in section.links" :key="link.text"  class="m-0 p-0 text-uppercase small">
                                            <li class="text-blue py-1">
                                                <b-link :href="link.url">{{ link.text }}</b-link>
                                            </li>
                                        </ul>
                                    </b-nav>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </div>
            </div>
        </b-container>

        <!-- Logos -->
        <b-container fluid class="bg-white py-3">
            <div class="d-flex justify-content-center">
                <div style="max-width:1200px; width: 100%">
                    <b-row class="justify-content-between align-items-center border-bottom pb-4">
                        <b-col v-for="lab in labLogos" :key="lab.name" cols="auto">
                            <b-link :href="lab.url" :title="lab.name">
                                <b-img class="lab-logo" :src="lab.logoSrc" :alt="`${lab.name} logo`"></b-img>
                            </b-link>
                        </b-col>
                    </b-row>
                </div>
            </div>
        </b-container>

      <!-- Disclaimers -->
        <b-container fluid class="bg-white py-3">
            <div class="d-flex justify-content-center">
                <div style="max-width: 1200px; width: 100%">
                    <b-row class="justify-content-center">
                        <b-col v-for="link in disclaimerLinks" :key="link.text" cols="auto" >
                            <b-link :href="link.url" class="text-blue"><span class="text-disclaimer"><strong>{{ link.text }}</strong></span></b-link>
                        </b-col>
                    </b-row>
                    <b-row class="justify-content-center mt-3">
                        <b-col cols="12" class="text-center">
                            <span class="text-navy "><strong> Atmospheric Radiation Measurement (ARM) | Reviewed {{ reviewDate }}</strong></span>
                        </b-col>
                    </b-row>
                </div>
            </div>
        </b-container>
    </footer>
  </template>

  <script>
  import Config from '../config'
  export default {
    name: 'ArmFooter',
    data() {
      return {
        socialLinks: [
          { name: 'Facebook', url: 'https://www.facebook.com/arm.gov', logoSrc: 'https://armgov-wp.stg.arm.gov/wp-content/uploads/5facebook-1-1.png' },
          { name: 'Instagram', url: 'https://www.instagram.com/armuserfacility/', logoSrc: 'https://armgov-wp.stg.arm.gov/wp-content/uploads/6instagram-1-1.png' },
          { name: 'LinkedIn', url: 'https://www.linkedin.com/company/18544925/', logoSrc: 'https://armgov-wp.stg.arm.gov/wp-content/uploads/3linkedin-1-1.png' },
          { name: 'X', url: 'https://x.com/armnewsteam', logoSrc: 'https://armgov-wp.stg.arm.gov/wp-content/uploads/4twitter-1-1.png' },
          { name: 'Flickr', url: 'https://www.flickr.com/photos/armgov/', logoSrc: 'https://armgov-wp.stg.arm.gov/wp-content/uploads/2flickr.png' },
          { name: 'Youtube', url: 'https://www.youtube.com/@armuserfacility9698', logoSrc: 'https://armgov-wp.stg.arm.gov/wp-content/uploads/1youtube-logo.png' },
        ],
        navSections: [
          {
            title: 'Data',
            links: [
              { text: 'Data Discovery', url: 'https://adc.arm.gov/discovery/#/' },
              { text: 'Observatories & Instruments', url: `${Config.armgov.url}/data/data-sources?loctype=P&loctype=M&loctype=L&type=armobs` },
              { text: 'Campaigns', url: `${Config.armgov.url}/research/campaigns` },
              { text: 'Data Use Guidance', url: `${Config.armgov.url}/guidance/datause` },
            ]
          },
          {
            title: 'About',
            links: [
              { text: 'Careers', url: `${Config.armgov.url}/working-with-arm/careers` },
              { text: 'Media Outreach', url: `${Config.armgov.url}/resources/outreach` },
              { text: 'Leadership & Organization', url: `${Config.armgov.url}/about/leadership-organization` },
              { text: 'User Statistics', url: `${Config.armgov.url}/user-program/stats` },
            ]
          },
          {
            title: 'Resources',
            links: [
              { text: 'Glossary', url: `${Config.armgov.url}/resources/glossary` },
              { text: 'Acronyms', url: `${Config.armgov.url}/resources/acronyms` },
              { text: 'Help', url: 'mailto:adc@arm.gov' },
            ]
          }
        ],
        labLogos: [
          { name: 'Argonne National Laboratory', url: 'http://www.anl.gov', logoSrc: `${Config.armgov.url}/img/lab-logos/anl.png` },
          { name: 'Brookhaven National Laboratory', url: 'http://www.bnl.gov', logoSrc: `${Config.armgov.url}/img/lab-logos/bnl.png` },
          { name: 'Los Alamos National Laboratory', url: 'http://www.lanl.gov', logoSrc: `${Config.armgov.url}/img/lab-logos/lanl.png` },
          { name: 'Lawrence Berkeley National Laboratory', url: 'http://www.lbl.gov', logoSrc: `${Config.armgov.url}/img/lab-logos/lbnl.png` },
          { name: 'Lawrence Livermore National Laboratory', url: 'http://www.llnl.gov', logoSrc: `${Config.armgov.url}/img/lab-logos/llnl.png` },
          { name: 'National Renewable Energy Laboratory', url: 'http://www.nrel.gov', logoSrc: `${Config.armgov.url}/img/lab-logos/nrel.png` },
          { name: 'Oak Ridge National Laboratory', url: 'http://www.ornl.gov', logoSrc: `${Config.armgov.url}/img/lab-logos/ornl.png` },
          { name: 'Pacific Northwest National Laboratory', url: 'http://www.pnnl.gov', logoSrc: `${Config.armgov.url}/img/lab-logos/pnnl.png` },
          { name: 'Sandia National Laboratories', url: 'http://www.sandia.gov', logoSrc: `${Config.armgov.url}/img/lab-logos/sandia.png` },
        ],
        disclaimerLinks: [
          { text: 'Accessibility', url: 'https://www.energy.gov/cio/accessibility-standard-statement' },
          { text: 'Privacy', url: `${Config.armgov.url}/guidance/privacy-security-notice` },
          { text: 'No FEAR Act', url: 'https://www.energy.gov/justice/no-fear-act-data' },
          { text: 'Inspector General', url: 'https://www.energy.gov/ig/office-inspector-general' },
          { text: 'Information Quality', url: 'https://www.energy.gov/cio/department-energy-information-quality-guidelines' },
          { text: 'Website Policies', url: 'https://www.energy.gov/web-policies' },
          { text: 'Vulnerability Disclosure Program', url: 'https://www.energy.gov/cio/articles/vulnerability-disclosure-policy' },
        ],
        ArmLogo: `${Config.armgov.url}/img/arm-logo.png`,
        ArmLogoReverse: `${Config.armgov.url}/img/arm-logo-reverse.png`,
        reviewDate: 'October 2024'
      }
    },
  }
  </script>

  <style scoped>
  @import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');
    .bg-darknavy {
        background-color: #184174 !important;
        opacity: 1;
    }
    .bg-lightblue{
        background-color: #E6F1FF !important;
        opacity: 1;
    }
    .text-navy-header {
        font-family: "Roboto Condensed", serif ;
        color: rgb(24, 65, 116) !important;
        letter-spacing: 0;
        font-size: large;
        font-weight: 400;
    }
    .text-navy {
        font-family: "Roboto", serif    ;
        color: rgb(24, 65, 116) !important;
        letter-spacing: 0;
        font-weight: 300;
        font-size: .875rem;

    }
    .text-blue {
        font-family: "Roboto", serif !important;
        color: rgb(0, 72, 147) !important;
        letter-spacing: 0;
        font-size: small;
        list-style-type: none;
        max-width: fit-content;
        text-align: left;
    }
    .text-white {
        font-family: "Roboto", serif !important;
        color: white !important;
        letter-spacing: 0;
    }
    .border-navy {
        border-color: rgb(24, 65, 116) !important;
    }
    .border-blue {
        border-color: rgb(0, 72, 147) !important;
    }
    .footer-btn {
        background-color: white;
        color: rgb(24, 65, 116) !important;
        max-width: 120px; /* Adjust as needed */
        padding: 0.375rem 0.75rem; /* Adjust as needed */
    }
    .footer-btn-r .footer-btn-r:hover{
        background-color: rgb(24, 65, 116) !important;
        color: white !important;
        padding: 0.375rem 0.75rem;
        max-width: 35%
    }
    .border-armgreen  {
        border-color: #00DD82 !important;
        opacity: 1;
        border-width: medium !important;
        max-width: stretch;
    }
    .lab-logo {
        max-width: auto ;
        max-height: 33px;
    }
    .text {
        color: rgb(24, 65, 116) !important;
    }
    .title {
        color: rgb(24, 65, 116) !important;
    }
    .icon {
        padding-left: 3px;
    }
    .text-disclaimer {
        color: rgb(24, 65, 116) !important;
    }
    .text-white-heading {
        font-family: "Roboto Condensed", sans-serif;
        color: white;
        font-size: large;
        letter-spacing: 0em;
        margin: 0;
    }
    .text-white-content {
        font-family: "Roboto Condensed", sans-serif;
        color: white;
        font-size: medium;
        letter-spacing: 0em;
        margin: 0;
    }
    .button-text {
        font-family: "Roboto", serif;
        text-align: center;
        letter-spacing: 0;
        font-size: small;
    }

    .nav-header {
        font-family: "Roboto Condensed", serif !important;
        color: rgb(24, 65, 116) !important;
        font-size: large;
    }
  /* Add any additional custom styles here */
  </style>
