<script>
import Config from "../config"

export default {
  computed: {
    allowedCharacters() {
      return Config.allowedCharacters
    },
    restrictedCharacters() {
      return Config.restrictedCharacters
    },
  },
}
</script>

<template>
  <div>
    <div class="section">
      <h3>Required fields</h3>
      Fields marked with an
      <strong class="text-danger">*</strong>
      are required.
      <br />
      The following characters are
      <b>allowed</b>
      when filling out the form:
      <ul>
        <li>Alpha-numeric characters</li>
        <li>
          Characters and punctuation, including
          <span id="allowedCharacters">
            {{ allowedCharacters }}
          </span>
        </li>
      </ul>
      The following characters are
      <b>not allowed</b>
      when filling out the form:
      <span id="restrictedCharacters">
        {{ restrictedCharacters }}
      </span>
    </div>
  </div>
</template>

<style scoped>
#allowedCharacters, #restrictedCharacters {
  font-family: monospace;
  font-weight: bold;
}
</style>
