<template>
    <b-container fluid>
        <TagHeader :title="title" :buttons="buttons"></TagHeader>
        <ResetContent />
        <ResetEmail />
    </b-container>
</template>

<script>
    import TagHeader from "@/components/TagHeader"
    import ResetContent from "@/components/ResetContent"
    import { mapState } from "vuex"
    import ResetEmail from "../components/ResetEmail";

    export default {
        name: "Login",
        components: {
            ResetEmail,
            TagHeader,
            ResetContent
        },
        data() {
            return {
                title: "Update Account Details",
                breadCrumbTitle: "Connect with ARM > Account Management",
                buttons: [
                    {name:"edit", label:"Personal Profile"},
                    {name:"update", label:"Subscriptions"},
                    {name:"logout", label:"Logout"}
                ]
            }
        },
        // computed: {
        //     ...mapState("auth",["details"]),
        // },
        // created() {
        //     this.$store.dispatch("auth/validateUserSession").then((response) =>{
        //         if (!response.status === 200 || !this.details.username) {
        //             this.$router.push({name: "login"})
        //         }
        //     }).catch(error => {
        //         this.$router.push({name: "login"})
        //     })
        // },
    }
</script>

<style scoped>

</style>