<template>
    <div>
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
            label-for="reason-select"
            invalid-feedback="Reason is required"
            :state="reasonState"
            required
        >
            <b-form-select
                v-model="reason"
                class="modal-text rounded-0"
                :options="options"
                required
            >
            </b-form-select>
        </b-form-group>

        <b-form-group
          label-for="name-input"
          invalid-feedback="Name is required"
          :state="nameState"
        >
          <b-form-input
            id="name-input"
            v-model="name"
            class="modal-text rounded-0"
            placeholder = "Your Name"
            :state="nameState"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label-for="email-input"
          invalid-feedback="Email is required"
          :state="emailState"
        >
          <b-form-input
            id="email-input"
            v-model="email"
            class="modal-text rounded-0"
            :state="emailState"
            placeholder = "Your Email"
            type="email"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label-for="description-input"
          invalid-feedback="Description is required"
          :state="descriptionState"
        >
          <b-form-textarea
            id="description-input"
            v-model="description"
            class="custom-textarea modal-text rounded-0"
            placeholder="Please describe your question here in detail."
            :state="descriptionState"
            required
          ></b-form-textarea>
        </b-form-group>
        <div class="d-flex align-items-center justify-content-center rounded-0 mb-4">
          <VueRecaptcha
            :sitekey="getRecaptchaKey"
            :loadRecaptchaScript="true"
            @verify="verify"
            @expired="expired"
            @error="recaptchaError"
            @render="render"
          />
        </div>
        <div class="d-flex align-items-center justify-content-center flex-column">
            <b-button
                type="submit"
                variant="arm-primary"
                class="w-100 send-button rounded-0">
                <b-icon icon="envelope-fill" />
                <span class="pl-2">Send Message</span>
            </b-button>
            <p  v-if="messageSent" class="pt-4 text-success"> Message sent successfully!</p>
        </div>

      </form>
    </div>
  </template>
  <script>
    import { email } from 'vuelidate/lib/validators';
    import VueRecaptcha from "vue-recaptcha"
    import Config from '../config'
    import axios from "axios";

    export default {
    components: {
        VueRecaptcha,
    },
    data() {
        return {
            reason: null,
            name: '',
            email: '',
            description: '',
            reasonState: null,
            nameState: null,
            emailState: null,
            descriptionState: null,
            messageSent: false,
            captchaResponse: "",
            verified: true,
            recaptchaLoaded: false,


            options: [
                {
                    value: null,
                    text: "I need help with..."
                },
                {
                    value: "ARM - Campaigns and Facilities Access",
                    text: "Campaigns and Accessing Arm Facilities",
                },
                {
                    value: "ARM Discovery & Distribution",
                    text: "Finding Arm Data",
                },
                {
                    value: "ARM - DQ Tools Support",
                    text: "Report Data Quality Tools",
                },
                {
                    value: "ARM - Website",
                    text: "ARM Website",
                },
                {
                    value: "ARM - User Account Support",
                    text: "ARM User Account",
                },
                {
                    value: "ARM - General Help Desk",
                    text: "Other",
                },
            ],
        }
      },
      computed: {
        getRecaptchaKey () {
              return Config.recaptchaSiteKey
            },
      },
      methods: {
        recaptchaError() {
                console.error("Couldn't load recaptcha")
            },
        expired() {
                this.verified = false
            },
        render() {
                this.isRecaptchaLoaded = true
            },
        verify(response) {
            this.captchaResponse = response
            this.verified = true
        },
        checkFormValidity() {
          const valid = this.$refs.form.checkValidity()
          this.reasonState = valid
          this.nameState = valid
          this.descriptionState = valid
          this.emailState = valid
          return valid
        },
        resetModal() {
          reason: null,
            this.name= '';
            this.email= '';
            this.description= '';
            this.reasonState= null;
            this.nameState= null;
            this.emailState= null;
            this.descriptionState= null;
            this.verified= false;
            this.messageSent = false;
        },
        handleOk(bvModalEvent) {
          // Prevent modal from closing
          bvModalEvent.preventDefault()
          // Trigger submit handler
          this.handleSubmit()
        },
        async sendContactRequest(){
          try {
            const response = await axios({
              method: "post",
              url: `${Config.notificationService.url}/createServiceNowIncident`,
              data: {
                "assignment_group": this.reason,
                // "g-recaptcha-response": this.recaptchaResponse,
                "watch_list": this.email,
                "caller_id": this.name,
                "description": this.description,
                "short_description": "Contact Request from " + this.name + " (" + this.email + ")"
              },
              config: { headers: {"Content-Type": "application/json" }},
              withCredentials: true
            });

            if (response.status === 200) {
              console.log("Message Sent Successfully")
              this.messageSent = true
              setTimeout(() => {
                this.resetModal();
              }, 15000);

            }
          } catch (error) {
            console.error('Error sending contact request:', error);
            this.messageSent = false
          }

        },
        handleSubmit() {
          // Exit when the form isn't valid
          if (this.verified == false) {
            alert("Please verify that you are not a robot.")
            return
          }
          if (!this.checkFormValidity()) {
            return
          }
          // Push the name to submitted names
          this.sendContactRequest()
          this.$nextTick(() => {
            this.$bvModal.hide('modal-prevent-closing')
          })
        },
        handleRecaptchaSuccess (response) {
          this.verified= true
          this.recaptchaResponse = response
        },
        handleRecatpchaExpired (response) {
          this.verified = false
          this.recaptchaResponse = ''
        },
      }
    }
  </script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');
    .custom-textarea {
        height: 66px;
        resize: none;
        overflow-y: auto;
    }
    .send-button {
        font-family: "Roboto", serif !important;
        background-color: white;
        color: #004893;
        border-color: lightgray;
        text-transform: uppercase;
        letter-spacing: 0;
    }

    .send-button:hover {
        color: #184174 !important;
        background-color: white !important;
        border-color: lightgray;
    }

    .modal-text {
      font-family: "Roboto", serif !important;
      letter-spacing: 0;
      color: #1a202c;
    }

</style>