<template>
    <div class="section1">
        <div class="section bordered">
            <div class="content" data-section-name="generalinfo">
                <p>Please login using your Username or Email associated with ARM.</p>
                <b-form @submit="onSubmit">
                    <FormInput label="Username" :value="username" name="username" :required=required @inputUpdated="updateInput"/>
                    <FormInput label="Password" :value="password" name="password" :required=required @inputUpdated="updateInput" type="password" />
                    <b-row class="mt-3">
                        <b-col class="text-blue" offset-lg="3"><router-link to="forgot">Forgot username or password?</router-link></b-col>
                    </b-row>
                    <b-row class="mt-2">
                        <b-col offset-lg="3"><small>Not registered? Registration is free and easy.</small></b-col>
                    </b-row>
                    <b-row>
                        <b-col class="text-blue" offset-lg="3"><router-link to="new">Sign up and use your account immediately!</router-link></b-col>
                    </b-row>
                    <b-row class="mt-3">
                        <b-col offset-lg="3" lg="6"><b-button type="submit" block variant="arm-primary">Submit</b-button></b-col>
                    </b-row>
                </b-form>
            </div>
        </div>
        <b-row class="mt-4">
            <b-col lg="10">
                <b-alert :show="showError" variant="danger"><strong>Login unsuccessful!</strong> The username or password entered does not match with our records. If this problem persists, please use the
                    <router-link to="/forgot">ARM account request page</router-link> to request your details</b-alert>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import FormInput from "@/components/FormInput"
    import { mapState } from "vuex"
    export default {
        name: "LoginContent",
        components: {
            FormInput
        },
        data() {
            return {
                username: "",
                password: "",
                showError: false,
                errorMsg: "",
                required: true
            }
        },
        computed: {
            ...mapState("auth",["details", "errorMessage"]),
        },
        mounted() {
            this.init()
        },
        watch: {
            $route: "init",
            errorMessage: "init",
            details: "init"
        },
        methods: {
            init() {
                if ((this.errorMessage === null || this.errorMessage == "") && this.details.username) {
                    this.$router.push({name: "dashboard"})
                } else {
                    this.showError = this.username && this.password ? true : false
                }
            },
            onSubmit(evt) {
                evt.preventDefault()
                if(this.username && this.password){
                    var payload = {
                        username: this.username,
                        password: this.password
                    }
                    this.$store.dispatch("auth/validateLogin", payload)
                    if (this.errorMessage === null && this.details.username)
                        this.$router.push({name: "dashboard"})
                } else {
                    this.showError = true
                }
            },
            updateInput(payload) {
                this[payload.label] = payload.value
            }
        }
    }
</script>

<style scoped>
    .btn {
        /* background-color: #00BD70 !important; */
    }
    .text-blue {
        color: #206FC1 !important;
    }
</style>