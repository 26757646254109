<template>
    <div class="section1">
        <div class="section bordered">
            <h2>Newsletter & Communications</h2>
            <div class="content" data-section-name="newsletter">
                <b-row>
                    <b-col lg="6">
                        <p>Please choose the type of information you would like to receive: <span class="arm-required">*</span></p>
                    </b-col>
                    <b-col lg="6">
                        <b-alert show class="arm-alert">
                            <b-form-group>
                                <b-form-checkbox v-model="newsletter" value="arm" @change="selectionUpdated"><strong>Atmospheric Observer</strong> - ARM’s user facility monthly email newsletter. <i>The Atmospheric Observer</i> contains science news and user features, data and event announcements, ARM proposal calls, and research highlights.</b-form-checkbox>
                                <b-form-checkbox v-model="newsletter" value="asr" class="mt-2" :disabled="isBtnDisabled" @change="selectionUpdated"><strong>ASR News</strong> - ASR’s monthly email newsletter. <i>ASR News</i> contains news and features about the people and science of the Atmospheric System Research program, funding opportunities, and other important information for the atmospheric science community.</b-form-checkbox>
                                <label class="ml-4 mt-3"><strong>ASR Working Group Information</strong></label>
                                <p class="ml-4">
                                    Connect with other research teams working on the various cloud, aerosol, land, and precipitation processes and process interactions. Working group members will also receive the <i>ASR News</i> email newsletter.
                                </p>
                                <b-form-checkbox-group v-model="workingGroupSelected" class="ml-4" @change="selectionUpdated">
                                    <b-form-checkbox v-for="item in workingGroups" :key="item.value" :value="item.value" class="mt-2"><strong>{{ item.text }}</strong> - {{item.description}}</b-form-checkbox>
                                </b-form-checkbox-group>
                              <b-form-checkbox v-model="unsubscribed" @change="selectionUpdated" class="mt-2">
                                <strong v-if="isEdit">Unsubscribe</strong>
                                <strong v-else>Opt Out</strong>
                                - I do not wish to receive newsletters or other ARM/ASR communications, including funding opportunities, proposal calls, and data and event announcements.
                              </b-form-checkbox>
                              <b-form-invalid-feedback
                                  v-if="selectionUpdated"
                                  :state="validateState('newsletter')"
                                  class="thick"
                              >
                                Subscription is required
                              </b-form-invalid-feedback>
                            </b-form-group>
                        </b-alert>
                    </b-col>
                </b-row>
            </div>
            <b-row v-if="isEdit" class="mt-4">
                <b-col offset-lg="6" lg="6"><b-button block variant="arm-primary" @click="onSubmit" :disabled="!isSubmitAllowed">Submit</b-button></b-col>
            </b-row>
        </div>

        <!--         Commenting 'Comments' based on feedback received from Shannon - INC0014965-->
        <!--        <div class="section bordered">-->
        <!--            <h2>Comments</h2>-->
        <!--            <div class="content" data-section-name="comments">-->
        <!--                <FormTextArea label="Please enter any comments in the text to the right." :required=required name="comments" :value="comments" @textAreaUpdated="textAreaUpdated"/>-->
        <!--            </div>-->
        <!--        </div>-->
        <b-row v-if="isEdit" class="mt-3">
            <b-col offset-lg="6" lg="6">
                <b-alert :show="isAccountUpdated && updateSuccess" variant="success">
                    <h3>Success!</h3>
                    Your information has been successfully updated.
                </b-alert>
                <b-alert :show="isAccountUpdated && !updateSuccess" variant="danger" class="thick">Sorry, an error occurred while saving your information. Please contact adc@arm.gov.
                </b-alert>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import { mapState } from "vuex"
    import Config from "../config";
    import axios from "axios";
    import { validationMixin } from "vuelidate";
    import {required, requiredIf} from "vuelidate/lib/validators";
    export default {
        name: "SubscriptionContent",
        mixins: [validationMixin],
        props: {
            loadValues: {default: false, type: Boolean},
            newsletterVals: {default: null, type: Array},
            workingGroupsVals: {default: null, type: Array},
            type: {default: null, type: String},
        },
        data() {
            return {
                unsubscribed: "false",
                required: false,
                isAccountUpdated: false,
                updateSuccess: false,
                newsletter: [],
                workingGroupSelected: [],
                username: "",
                isEdit: false,
                selectionChanged: false,
                sectionValid: false
            }
        },
        computed: {
            ...mapState("profile",["subscriptionDetails", "verficationCode"]),
            ...mapState("app",["workingGroups"]),
            isBtnDisabled() {
                return this.workingGroupSelected && this.workingGroupSelected.length > 0 ? true : false
            },
          isSubmitAllowed() {
              return !this.isAccountUpdated && this.sectionValid
          },
          validationObject() {
            return JSON.stringify(this.$v)
          },
        },
      validations: {
          newsletter: {
            required: requiredIf(function() {
              return !this.unsubscribed || this.unsubscribed === "false"
            })
          },
      },
        mounted() {
            this.init()
        },
        watch: {
            subscriptionDetails:  "setDataValues",
            $route: "init",
            loadValues: "setNewsletterVals",
            workingGroupSelected(val) {
              this.workingGroupSelected = val
              this.updateNewsletterVals()
            },
            "unsubscribed": {
                handler(newVal) {
                  if (newVal === true) {
                    this.newsletter = []
                    this.workingGroupSelected = []
                    this.emitValUpdate()
                  }
                }
            },
            "newsletter": {
              handler(newVal, oldVal) {
                if ((newVal?.includes("asr") || newVal?.includes("arm")) && this.unsubscribed === true) {
                  this.unsubscribed = false
                  this.emitValUpdate()
                } else if (!(newVal?.includes("asr") || newVal?.includes("arm"))) {
                  this.unsubscribed = true
                }
              },
              deep: true
            },
            validationObject: {
              handler(newVal, oldVal) {
                const a = JSON.parse(newVal)
                const b = JSON.parse(oldVal)
                Object.keys(a.$params).forEach(key => {
                  if (a[key].$model !== b[key].$model) {
                    this.$v[key].$touch()
                    this.sectionValid = !this.$v.$invalid;
                    this.$emit("subscriptionSectionValid", !this.$v.$invalid)
                  }
                })
              },
              deep: true,
            },
        },
        created() {
            if (!this.isEdit) {
                this.emitValUpdate()
            }

        },
        methods: {
          validateState(name) {
            var { $dirty, $error } = this.$v[name];
            return $dirty ? !$error : null;
          },
          selectionUpdated() {
            if (!this.selectionChanged) {
              this.selectionChanged = true
            }
          },
            setNewsletterVals() {
                if (this.loadValues) {
                    this.newsletter = this.newsletterVals
                    this.workingGroupSelected = this.workingGroupsVals
                }
            },
            emitValUpdate() {
              var payload = {
                  newsletter: this.newsletter,
                  workingGroups: this.workingGroupSelected
              }
              this.$emit('newsletterUpdate', payload)
            },
            updateNewsletterVals() {
                if(this.workingGroupSelected && this.workingGroupSelected.length > 0) {
                    if(this.newsletter && !this.newsletter.includes("asr")) {
                        this.newsletter.push("asr")
                    }
                }
                if (!this.isEdit) {
                    this.emitValUpdate()
                }
            },
            init() {
                if (this.$route.params.id) {
                    this.isEdit = true
                    this.$store.dispatch("profile/getSubscriptionDetails" , this.$route.params.id).then((response) =>{
                        this.setDataValues()
                    }).catch(error => {
                        console.log("error",error)
                    })
                }
            },
            setDataValues() {
                if (this.subscriptionDetails) {
                  this.newsletter = this.subscriptionDetails.newsletterTypes
                  this.workingGroupSelected = this.subscriptionDetails.workingGroups
                  this.userName = this.subscriptionDetails.userName
                }

            },
            onSubmit(ev) {
                var url = this.type && this.type === "limitedNewsUpdate" ? `${Config.api.url}/users/update/newsletter?token=${this.verficationCode}` : `${Config.auth.url}/armuserreg-api/account/update/newsletter`
                ev.preventDefault()
                var self = this
                var payload = {
                    people: {
                        userName: this.userName,
                        email: this.$route.params.id
                    },
                    newsletterTypes: this.newsletter,
                    workingGroups: this.workingGroupSelected
                }
                axios({
                    method: "post",
                    url: url,
                    data: payload,
                }).then((response) => {
                    console.log(response)
                    self.isAccountUpdated = true
                    if(response.data !== null && response.data.trim().length > 0) {
                        self.updateSuccess = false
                    } else {
                        self.updateSuccess = true
                    }
                }).catch((error) => {
                    console.log(error)
                })
            },
            textAreaUpdated (payload) {
                this[payload.label] = payload.value
            }
        }
    }
</script>

<style scoped>

</style>
