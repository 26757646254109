<template>
    <div class="section6">
        <div class="section bordered">
            <h2>Account Details</h2>
            <div class="content" data-section-name="accountDetailsInfo">
                <b-row class="mt-4">
                  <b-col lg="6">
                    <label class="thick">Please enter your preferred ARM Username<span class="arm-required">*</span></label>
                  </b-col>
                  <b-col lg="6">
                    <b-form-input
                      v-model="localPeople.userName"
                      class="rounded-0"
                      :state="validateUsernameMsg"
                      placeholder="Please enter your username"
                      aria-describedby="input-live-help username-feedback feedback"
                      @keyup="validateUserNameForInput"
                    />
                    <b-form-invalid-feedback
                        id="username-feedback"
                        class="thick"
                    >
                      {{usernameErrorMsg}}
                    </b-form-invalid-feedback>
                    <b-form-invalid-feedback
                        id="feedback"
                        class="thick"
                    >
                      Username is required and must meet the following requirements:
                      <ul>
                        <li>Must consist of lowercase alphanumeric characters</li>
                        <li>Must include at least one letter</li>
                        <li>Must be between 3 and 61 characters long</li>
                      </ul>
                    </b-form-invalid-feedback>
                  </b-col>
                </b-row>
                <b-row v-if="!isEditMode" class="mt-4">
                    <b-col><h3>Passwords must:</h3></b-col>
                </b-row>
                <b-row v-if="!isEditMode" class="mt-4">
                  <b-col lg="6">
                    <ul class="thick ml-2">
                      <li :class="validateErrorClass(tenCharRule)">{{tenCharRule.message}}</li>
                    </ul>
                  </b-col>
                </b-row>
                <b-row v-if="!isEditMode" class="mt-4">
                    <b-col><h3>And have at least 3 out of 4:</h3></b-col>
                </b-row>
                <b-row v-if="!isEditMode">
                    <b-col lg="6">
                        <ul class="thick ml-2">
                            <li v-for="rule in rules" :key="rule.message" :class="validateErrorClass(rule)">{{rule.message}}</li>
                        </ul>
                    </b-col>
                    <b-col lg="6">
                        <b-form-input
                                v-model="newPassword"
                                class="rounded-0"
                                :state="isPasswordValid"
                                placeholder="Password"
                                aria-describedby="feedback"
                                type="password"
                                trim
                        ></b-form-input>
                        <b-form-invalid-feedback :state="isPasswordValid" class="thick">The password you entered does not meet the minimum requirements.</b-form-invalid-feedback>
                        <b-form-input
                                v-model="verifyPassword"
                                :state="samePasswords"
                                placeholder="Confirm password"
                                class="rounded-0 mt-3"
                                aria-describedby="feedback"
                                type="password"
                                trim
                        ></b-form-input>
                        <b-form-invalid-feedback :state="samePasswords" class="thick">Password and Confirm Password do not match.</b-form-invalid-feedback>
                    </b-col>
                </b-row>
                <b-row class="mt-4">
                    <b-col lg="6">
                        <p>
                            <b-badge variant="danger">IMPORTANT</b-badge> The information
                            you submit will be reviewed. Access to ARM resulting from
                            registrations with insufficient content or intentionally false
                            information will be disabled. ARM will contact you if more
                            information is needed with your registration. Thank you for your
                            cooperation during this process. <br> <br> Beginning
                            in fiscal year 2015, the U.S. Department of Energy Office of
                            Science (SC), which is the primary sponsor of ARM, has required
                            that a limited set of information relating to your user
                            project/experiment be transmitted to the SC. A subset of this
                            information, including your name, institution affiliation(s) and
                            project title(s) will be publicly disseminated yearly as part of
                            an SC user projects experiments database on the SC website at <a
                                target='_blank' href='http://science.energy.gov'>http://science.energy.gov</a>
                            after the conclusion of the fiscal year. For proprietary
                            projects, SC requests that the user provide a project title
                            suitable for public dissemination.
                        </p>
                    </b-col>
                    <b-col lg="6">
                        <b-alert show class="arm-alert">
                            <b-form-checkbox
                                    v-model="policyCheckbox"
                                    :value="true"
                                    :unchecked-value="false"
                                    :state="validateState('policyCheckbox')"
                            >
                                I agree to the <strong>ARM Registration Policy</strong>.<span
                                    class="arm-required thick" title="Please fill out this field.">
										*</span>
                            </b-form-checkbox>
                            <b-form-invalid-feedback :state="validateState('policyCheckbox')" class="thick">You must accept the ARM access Policy.</b-form-invalid-feedback>
                        </b-alert>
                        <b-alert
                          :show="!isEditMode"
                          variant="warning"
                        >
                          <VueRecaptcha
                            :sitekey="getRecaptchaKey"
                            :loadRecaptchaScript="true"
                            @verify="verify"
                            @expired="expired"
                            @error="recaptchaError"
                            @render="render"
                          />
                          <MathCaptcha
                            v-if="!isRecaptchaLoaded"
                            @mathCaptchaValid="mathCaptchaResponse = $event"
                          />
                        </b-alert>
                        <b-row
                          class="mt-4"
                          v-if="!hideSubmit"
                        >
                            <b-col v-if="!isEditMode">
                              <b-button
                                block
                                variant="arm-primary"
                                @click="onSubmit"
                                :disabled="!isDisabled"
                              >
                                Submit
                              </b-button>
                            </b-col>
                            <b-col v-else>
                              <p v-if="displayUpdateMessage"><b-badge variant="danger">IMPORTANT</b-badge> By submitting your update, you are confirming that your profile information is correct as shown. Any updates you have entered will be captured upon submit.</p>
                              <b-button block variant="arm-primary" @click="initiateUpdate" :disabled="!isUpdateDisabled">Submit Update</b-button>
                            </b-col>
                        </b-row>
                        <b-row class="mt-3">
                            <b-col>
                                <b-alert :show="updateSuccess" variant="success">
                                    <div v-if="isEditMode">
                                        <h3>Success!</h3>
                                        Your information has been successfully updated.
                                    </div>
                                    <div v-else>
                                       <h3>Please confirm your email address!</h3>
                                        <hr>
                                        <p>We have sent an email to confirm your email address. To complete the registration process, please click the confirmation link. <br><br>
                                            If you do not receive the confirmation email within a few minutes of signing up, please check your Spam folder just in case the email got delivered there instead of your inbox. If you need assistance, please contact us at <a href="mailto:accounts@arm.gov">accounts@arm.gov</a>.
                                        </p>
                                    </div>
                                </b-alert>
                                <b-alert :show="isRecordSubmitted && !updateSuccess && hideSubmit" variant="danger" class="thick">
                                    Sorry, an error occurred while saving your information. Please contact adc@arm.gov.
                                </b-alert>
                                <b-alert :show="isRecordSubmitted && !updateSuccess && !hideSubmit" variant="danger" class="thick">
                                    Sorry! There is a problem with one or more of the fields submitted. They are now highlighted in the form.
                                </b-alert>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </div>
        </div>
        <UserNameUpdateModal
          :user-name="people.userName"
          :previous-user-name="previousUserName"
          @confirmUpdate="submitUpdate"
        />
    </div>
</template>

<script>
    import _ from "underscore"
    import VueRecaptcha from "vue-recaptcha"
    import Config from "../config"
    import { validationMixin } from "vuelidate";
    import { required } from "vuelidate/lib/validators";
    import {mapState} from "vuex";
    import MathCaptcha from "./MathCaptcha.vue";
    import UserNameUpdateModal from "./UserNameUpdateModal.vue";

    const usernameValidator = Config.validations.usernameValidator

    export default {
        name: "SignatureTemplate",
        mixins: [validationMixin],
        components: {
        VueRecaptcha,
        MathCaptcha,
        UserNameUpdateModal,
    },
        props: {
            isRecordSubmitted: {default: null, type: Boolean},
            updateSuccess: {default: null, type: Boolean},
            hideSubmit: {default: null, type: Boolean},
            sectionsValid: {default: null, type: Boolean},
            //updateSectionsValid: {default: null, type: Boolean},
            previousUserName: {default: null, type: String},
            email: {default: null, type: String},
            people: {default: null, type: Object},
            isEdit: {default: false, type: Boolean},
        },
      mounted() {
          this.localPeople = this.people
      },
        data() {
            return {
                localPeople: {},
                isUsernameUpdated: false,
                threeOutOfFourRulePassed: false,
                newPassword: "",
                verifyPassword: "",
                rules: [
                    {message: "One upper-case character", regex:/[A-Z]+/},
                    {message: "One lower-case character", regex:/[a-z]+/},
                    {message: "One number", regex:/[0-9]+/},
                    {message: "One of the following special characters: '#?!@$%^&*-'", regex:/[#?!@$%^&*-]/},
                ],
                tenCharRule: {message: "Be at least 10 characters long", regex:/.{10,}/},
                policyCheckbox: false,
                verified: false,
                isEditMode: false,
                submitted: false,
                isRecaptchaLoaded: false,
                captchaResponse: "",
                mathCaptchaResponse: false,
                usernameIsValid: false,
            }
        },
        validations: {
          policyCheckbox: {
            checked: value => value === true,
          },
          localPeople: {
            userName: {
              required,
              usernameValidator,
            },
          },
        },
        computed: {
            ...mapState("app",["isEnvDev"]),
            ...mapState("auth",["details"]),
            ...mapState("profile",["usernameErrorMsg"]),
            displayUpdateMessage() {
              return this.details && this.details.validateTime && !this.$moment(this.details.validateTime).isAfter(this.$moment().subtract(1, 'years')) ? true : false
            },
            getRecaptchaKey () {
              return Config.recaptchaSiteKey
            },
            isPasswordValid() {
                  var errors = []
                  _.each(this.rules, (rule) => {
                    if (!rule.regex.test(this.newPassword))
                      errors.push(rule.message)
                  })
                this.checkThreeOutOfFourRule(errors)
                  if (errors.length > 1) {
                    return false
                  } else {
                    if (this.newPassword.length >= 10) {
                      return true
                    } else {
                      return false
                    }
                  }

            },
            samePasswords () {
                return this.passwordsFilled() && (this.newPassword == this.verifyPassword) ? true : false
            },
            restrictedUsernameCharacters() {
              return Config.restrictedUsernameCharacters
            },
            isDisabled() {
              return this.policyCheckbox && this.sectionsValid && (this.verified || this.mathCaptchaResponse) && this.isPasswordValid && this.samePasswords && this.validateUsernameMsg
            },
          isUpdateDisabled() {
            return this.policyCheckbox && this.sectionsValid ? true : false
          },
          isUsernameDisabled() {
              return this.people?.personId ? true : false
          },
          validateUsernameMsg() {
                if (this.usernameErrorMsg || !this.localPeople.userName)
                  return false
                else {
                  return this.localPeople.userName.length < 2 ?
                      false :
                      this.validateState("username")
                }
            },
            validationObject() {
              return JSON.stringify(this.$v.localPeople)
            },

        },
        created() {
            this.init()
        },
        watch: {
            $route: "init",
            isRecordSubmitted() {
                this.$v.$touch();
            },
            validationObject: {
              handler(newVal, oldVal) {
                const a = JSON.parse(newVal)
                const b = JSON.parse(oldVal)
                Object.keys(a.$params).forEach(key => {
                  if (a[key].$model !== b[key].$model) {
                    this.$v.localPeople[key].$touch()
                    this.$emit("signatureSectionValid", !this.$v.localPeople.$invalid)
                  }
                })
              },
              deep: true,
             },
            "people.nameFirst"(newVal) {
              this.localPeople.nameFirst = newVal;
              if(!this.localPeople.personId) {
                this.validateUsername()
                this.$v.localPeople.userName.$touch()
              }
            },
            "people.nameLast"(newVal) {
              this.localPeople.nameLast = newVal;
              if (!this.localPeople.personId) {
                this.validateUsername()
                this.$v.localPeople.userName.$touch()
              }
            },
        },
        methods: {
          checkThreeOutOfFourRule(errors) {
            this.threeOutOfFourRulePassed = errors.length < 2
          },
            validateState(name) {
              const {$dirty, $error} = name === "username" ?
                this.$v.localPeople.userName :
                this.$v[name];
              return $dirty ? !$error : null;
            },
            async validateUsername() {
                this.usernameIsValid = false
                let counter = 0
                this.isUsernameUpdated = true
                while (!this.usernameIsValid && counter <= this.localPeople.nameFirst.length) {
                  let currUsername = this.localPeople.nameFirst.substring(0 , counter + 1).concat(this.localPeople.nameLast).toLowerCase()
                  let payload = {
                    username: currUsername,
                    email: this.email
                  }
                  const result = await this.$store.dispatch("profile/validateUsername", payload)
                  if (result === "") {
                    this.usernameIsValid = true
                  }
                  this.$emit('updateParent', currUsername)
                  this.localPeople.userName = currUsername;
                  counter++
                }
            },
            validateUserNameForInput() {
              if (this.localPeople.userName !== this.previousUserName) {
                this.isUsernameUpdated = true
                if (this.localPeople.userName) {
                  let payload = {
                    username: this.localPeople.userName,
                    email: this.email
                  }
                  this.$store.dispatch("profile/validateUsername", payload)
                  this.$emit('updateParent', this.localPeople.userName)
                }
              }
            },
            init() {
                if (this.$route.params.id) {
                    this.isEditMode = true
                } else {
                    this.isEditMode = false
                }
            },
            passwordsFilled () {
                return (this.newPassword !== '' && this.verifyPassword !== '')
            },
          validateErrorClass(condition) {
            if (condition.regex.test(this.newPassword)) {
              return "valid"
            } else {
              if (condition.message === "Be at least 10 characters long") {
                return "invalid"
              } else if (this.threeOutOfFourRulePassed) {
                return null
              } else {
                return "invalid"
              }
            }
          },
            updateInput(payload) {
                this[payload.label] = payload.value
            },
            verify(response) {
                this.captchaResponse = response
                this.verified = true
            },
            expired() {
                this.verified = false
            },
            validPasswords() {
                return this.isPasswordValid && this.samePasswords ? true : false
            },
            onSubmit(ev) {
              this.submitted = true
                ev.preventDefault()
                var payload = {
                    event: ev,
                    password: this.newPassword,
                    captchaResponse: this.captchaResponse,
                    mathCaptchaResponse: this.mathCaptchaResponse,
                    validPasswords: this.validPasswords
                }
                this.$emit('onSubmit', payload)

            },
            initiateUpdate(ev) {
              if (this.people?.userName !== this.previousUserName) {
                this.$bvModal.show("username-update-modal")
              } else {
                this.submitUpdate(ev)
              }
            },
            submitUpdate(ev) {
                this.$emit('submitUpdate', ev)
            },
            recaptchaError() {
                console.error("Couldn't load recaptcha")
            },
            render() {
                this.isRecaptchaLoaded = true
            }
        }
    }
</script>

<style scoped>
</style>
