import DevConfig from "@/config/config_dev.js"
import StageConfig from "@/config/config_stage.js"
import ProdConfig from "@/config/config_prod.js"

let Config = DevConfig

// This is replaced with dev, stage, or prod at deployment TimeRanges.
const appEnvironmentName = "prod"

if (appEnvironmentName == "dev") {
  Config = DevConfig 
} else if (appEnvironmentName == "stage") {
  Config = StageConfig 
} else if (appEnvironmentName == "prod") {
  Config = ProdConfig 
}

export default Config
