<template>
    <div class="section5">
        <div class="section bordered">
            <h2>Projects</h2>
            <div class="content" data-section-name="projectinfo">
                <b-row>
                    <b-col><h3>Project Information</h3></b-col>
                </b-row>
                <b-row>
                    <b-col lg="6">
                        <p>
                            Please provide project titles, funding sources, expected
                            start and end dates, and a brief description of your research project
                            using ARM facilities or data.
                        </p>
                        <p>
                            Thank you for your interest in data collected by the ARM Facility. As
                            a national scientific user facility, reporting the number of
                            scientific users is a critical performance metric for the ARM Facility and
                            important for the ongoing funding of the facility. Therefore, we
                            need to know how you are using the facilities or data for
                            scientific research.
                        </p>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col lg="6">
                        <label class="thick">Project title<span
                                class="arm-required" title="Please fill out this field."> *</span></label>
                        <p><b-badge variant="danger">IMPORTANT</b-badge>
                            Title of the project / experiment. Ideally, the title is the same or similar to the accepted user proposal under which the project / experiment was granted access to the facility resources.</p>
                    </b-col>
                    <b-col lg="6">
                        <b-form-input
                                title="Please fill out this field." placeholder="Project title"
                                class="rounded-0"
                                v-model="project.projectTitle" :state="validateState('projectTitle')" aria-describedby="feedback">
                        </b-form-input>
                        <b-form-invalid-feedback
                          id="feedback"
                          class="thick"
                        >
                          Project title is required and must:
                          <ul>
                            <li>Be between 4 and 250 characters</li>
                            <li>Consist of only alphanumeric characters and ()-:'",./</li>
                            <li>Not consist of only numbers</li>
                          </ul>
                        </b-form-invalid-feedback>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col lg="6">
                        <label class="thick">Project type<span
                                class="arm-required" title="Please fill out this field."> *</span>
                        </label>
                        <p>
                            <b-badge variant="warning">NOTE</b-badge> Most ARM data users have "non-proprietary" projects, meaning that they are planning to publish their results in the open scientific literature.
                        </p>
                    </b-col>
                    <b-col lg="6">
                        <b-form-select v-model="project.projectTypeCode" class="rounded-0" :options="projectTypeOptions" :state="validateState('projectTypeCode')" aria-describedby="feedback" @change="checkProjectType"></b-form-select>
                        <b-form-invalid-feedback id="feedback" class="thick">Project type is required</b-form-invalid-feedback>
                    </b-col>
                </b-row>
                <b-row class="mt-3">
                    <b-col lg="6">
                        <label class="thick">Research agency or organization funding your research<span
                                class="arm-required" title="Please fill out this field."> *</span>
                        </label>
                        Please indicate the source of funding for your research project using the ARM facility.
                        In most cases, this will be a federal or state research agency such as DOE, NASA, NOAA or NSF
                        or a similar international funding agency.
                        <span class="font-weight-bold">
                          Your institution should only be listed as the funding source if the research support is actually
                          from internal funding at your institution,
                        </span>
                        rather than from a research grant or contract.
                    </b-col>
                    <b-col lg="6">
                        <VueAutosuggest
                            v-model="fundingSource.name"
                            :suggestions="suggestions"
                            :inputProps="inputProps"
                            :getSuggestionValue="getSuggestionValue"
                            @input="fetchFundingOptions"
                            @selected="onSelected"
                        >
                            <template slot-scope="{suggestion}">
                                <span class="my-suggestion-item">
                                    {{ suggestion.item.name }}
                                </span>
                            </template>
                        </VueAutosuggest>

                        <b-form-invalid-feedback
                          id="feedback"
                          :state="validateState('fundingSource')"
                          class="thick"
                        >
                          Project funding is required and must not include invalid characters such as
                          {{ restrictedFundersCharacters }}
                        </b-form-invalid-feedback>
                    </b-col>
                </b-row>
                <b-row class="mt-3">
                    <b-col lg="6">
                        <label>Project date range</label>
                    </b-col>
                    <b-col lg="6">
                        <b-row>
                            <b-col cols="6">
                                <md-datepicker v-model="project.estStartDate" :md-disabled-dates="minDateDisabled" md-immediately>
                                    <label>Start date</label>
                                </md-datepicker>
                            </b-col>
                            <b-col cols="6">
                                <md-datepicker v-model="project.estEndDate" :md-disabled-dates="maxDateDisabled" md-immediately>
                                    <label>End date</label>
                                </md-datepicker>
                            </b-col>

                        </b-row>
                    </b-col>
                </b-row>
                <FormTextArea
                  class="mt-3"
                  label="Research statement"
                  name="researchStatement"
                  description="Please provide a brief description of your research project and how it will use ARM facilities or data."
                  :value="project.researchStatement"
                  :message="`Please provide a short (less than 1500 char) description of your work. A Research Statement is required, must be different than the project title, and must not include invalid characters such as ${restrictedCharacters}`"
                  :state="validateState('researchStatement')"
                  @textAreaUpdated="textAreaUpdated"
                />
            </div>
        </div>
        <b-modal id="projectConfirmModal" title="Please Confirm" ok-title="Confirm" no-fade ok-only hide-header-close no-close-on-backdrop centered @ok="confirmedProjectTypeModal">
            <p>
                Please confirm that your project is proprietary and you are not planning to publish results in the scientific literature.  ARM data access is free to all users, however other facility access for proprietary users is on a cost-recovery basis.
            </p>
        </b-modal>
    </div>
</template>

<script>
    import { mapState } from "vuex"
    import FormTextArea from "@/components/FormTextArea"
    import { validationMixin } from "vuelidate";
    import { required, requiredIf, not, sameAs } from "vuelidate/lib/validators";
    import { VueAutosuggest } from 'vue-autosuggest';
    import Config from "@/config"

    const oneOrMoreValidator = Config.validations.oneOrMoreValidator
    const fundersValidator = Config.validations.fundersValidator
    const projectTitleValidator = Config.validations.projectTitleValidator

    export default {
        name: "ProjectsTemplate",
        components: {
            FormTextArea,
            VueAutosuggest
        },
        mixins: [validationMixin],
        props: {
            project:  {default: null, type: Object},
            isRecordSubmitted: {default: false, type: Boolean}
        },
        data() {
            return {
                projectTitle: null,
                projectType: null,
                fundingSource: {
                    id: null,
                    name: null,
                },
                projectStartDate: null,
                projectEndDate: null,
                researchStatement: null,
                suggestions: [],
            }
        },
        validations: {
            project: {
                projectTitle: {
                    required,
                    projectTitleValidator,
                },
                projectTypeCode: {
                    required,
                    oneOrMoreValidator,
                },
                fundingSource: {
                    required: requiredIf(function() {
                        return this.project?.crossrefId ? false : true
                    }),
                    fundersValidator,
                },
                crossrefId: {
                    required: requiredIf(function() {
                        return this.project?.fundingSource ? false : true
                    }),
                    fundersValidator,
                },
                researchStatement: {
                    required,
                    oneOrMoreValidator,
                    differentFromProjectTitle: (value, vm) => {
                        return value !== vm.projectTitle
                    },
                },
            }
        },
        computed: {
            ...mapState("app",["projectTypeOptions", "fundingOptions"]),
            restrictedCharacters() {
              return Config.restrictedCharacters
            },
            restrictedFundersCharacters() {
              return Config.restrictedFundersCharacters
            },
            inputProps() {
                return {
                    id: "autosuggest__input1",
                    placeholder: "Research agency or organization funding your research",
                    class: "form-control" // Provides Bootstrap Styling to input
                }
            },
            validationObject() {
              return JSON.stringify(this.$v.project)
            },
        },
        watch: {
            isRecordSubmitted() {
                this.$v.project.$touch();
            },
            validationObject: {
                handler(newVal, oldVal) {
                  const a = JSON.parse(newVal)
                  const b = JSON.parse(oldVal)
                  Object.keys(a.$params).forEach(key => {
                    if (a[key].$model !== b[key].$model) {
                      this.$v.project[key].$touch()
                      this.$emit("projectValid", !this.$v.project.$invalid)
                    }
                  })
                },
                deep: true,
            },
            "project.fundingSource": {
                handler(newVal, oldVal) {
                    if (newVal && !oldVal) {
                        this.fetchFundingInfo()
                    }
                },
                deep: true,
            },
            "project.crossrefId": {
                handler(newVal, oldVal) {
                    if (newVal && !oldVal) {
                        this.fetchFundingInfo()
                    }
                },
                deep: true,
            },
        },
        created() {
            this.$store.dispatch("app/getProjectTypeOptions")
        },
        methods: {
            fetchFundingInfo() {
                if (this.project?.crossrefId) {
                    const prefix = Config.identifiers.crossref_id_prefix
                    const crossrefId = this.project.crossrefId
                    const uri = prefix + crossrefId
                    this.$store.dispatch("app/fetchFundingInfo", uri).then(response => {
                        if (response?.[0]?.name) {
                            this.fundingSource = response?.map(datum => {
                                return {
                                    id: crossrefId,
                                    name: datum?.name,
                                }
                            })[0]
                        }
                    })
                } else if (this.project?.fundingSource) {
                    this.fundingSource = {
                        id: this.project.fundingSource,
                        name: this.project.fundingSource,
                    }
                }
            },
            confirmedProjectTypeModal() {
                this.project.userTypeConfirm = true
            },
            checkProjectType() {
                // Display confirmation modal only for 'Proprietary (P)' selection and haven't confirmed it before
                if (this.project.projectTypeCode === "P" && !this.project.userTypeConfirm) {
                    this.$bvModal.show("projectConfirmModal")
                } else {
                    this.project.userTypeConfirm = null
                }
            },
            textAreaUpdated(payload) {
                this.project.researchStatement = payload.value
            },
            minDateDisabled(curDay) {
                if (this.project.estEndDate)
                    return curDay >= this.project.estEndDate
            },
            maxDateDisabled(curDay) {
                if (this.project.estStartDate)
                    return curDay <= this.project.estStartDate
            },
            //TODO: verify start and end dates on submission
            validateState(name) {
                const { $dirty, $error } = this.$v.project[name];
                return $dirty ? !$error : null;
            },
            getSuggestionValue(suggestion) {
                return suggestion.item
            },
            fetchFundingOptions(filter) {
                if (filter) {
                    this.project.crossrefId = null
                    this.$store.dispatch("app/searchFundingOptions", filter.toLowerCase()).then(response => {
                        this.suggestions = [{
                            data: response?.map(datum => {
                                const prefix = Config.identifiers.crossref_id_prefix
                                const uri = datum?.uri
                                const id = uri.replace(prefix, "")
                                return {
                                    id,
                                    name: datum?.name,
                                }
                            })
                        }]
                    })

                    this.fundingSource = {
                        id: filter,
                        name: filter,
                    }
                    this.project.fundingSource = filter
                } else {
                  this.fundingSource = {
                    id: null,
                    name: null,
                  }
                  this.project.fundingSource = null
                  this.project.crossrefId = null
                }
            },
            onSelected(text) {
                this.fundingSource = text.item
                this.project.fundingSource = text.item.name
                this.project.crossrefId = text.item.id
            },
        }
    }
</script>

<style>
    .projectDate .md-clear {
        display: none !important;
    }
    /********************************************
  Datepicker Overrides
********************************************/
    div.md-field.md-datepicker {
        border-radius: 0 !important;
        padding-top: 0px;
        padding-bottom: 0px;
        margin-bottom: 0px;
        background-color: #fff;
        min-height: 0px;
        border-radius: 5px;
    }
    div.md-field.md-datepicker label {
        margin-top: -16px;
    }
    div.md-field.md-datepicker.md-focused label {
        color: #fff;
    }
    div.md-field.md-datepicker.md-has-value label {
        color: #fff;
    }
    div.md-field.md-datepicker:focus label {
        color: #fff;
    }
    div.md-field.md-datepicker > i::after {
        display: none;
    }
    div.md-field.md-datepicker > .md-button {
        margin-top: -16px;
    }
    div.md-datepicker-header {
        background-color: black !important;
    }
    .md-datepicker-selected {
        color: white !important;
    }
    .md-datepicker-header {
        display: none;
    }
    div.md-field.md-datepicker {
        border: 1px solid #ced4da;
    }

</style>
