<template>
        <div class="section3">
            <div class="section bordered">
                <h2>Institutional Background</h2>
                <div class="content" data-section-name="institutionalinfo">
                    <b-row class="mt-3">
                        <b-col lg="6">
                            <label class="thick">Home institution name (unabbreviated)<span
                                    class="arm-required" title="Please fill out this field."> *</span>
                            </label>
                        </b-col>
                        <b-col lg="6">
                            <VueAutosuggest
                                v-model="affiliation.name"
                                :suggestions="suggestions"
                                :inputProps="inputProps"
                                :getSuggestionValue="getSuggestionValue"
                                @input="fetchAffiliationOptions"
                                @selected="onSelected"
                            >
                                <template slot-scope="{suggestion}">
                                    <span class="my-suggestion-item">
                                        {{ suggestion.item.name }}, {{ suggestion.item.country }}
                                    </span>
                                </template>
                            </VueAutosuggest>
                            <b-form-invalid-feedback
                              :state="validateState('affiliation')"
                              id="feedback"
                              class="thick"
                            >
                              Institution name is required and must not include invalid characters such as
                              {{ restrictedInstitutionsCharacters }}
                            </b-form-invalid-feedback>

                            <div
                                v-if="people.rorId"
                                class="institution-info p-2 mt-2"
                            >
                                ROR ID: {{ affiliation.id }}
                                <br />
                                Country: {{ affiliation.country }}
                            </div>
                        </b-col>
                    </b-row>
                    <b-row class="mt-4">
                        <b-col lg="6">
                            <label class="thick">Is your home institution a small business?<span
                                    class="arm-required" title="Please fill out this field."> *</span>
                            </label>
                        </b-col>
                        <b-col lg="6">
                            <b-form-radio-group v-model="people.isSmallBusiness" class="arm-radios" :options="businessOptions" :state="validateState('isSmallBusiness')" stacked>
                                <b-form-invalid-feedback :state="validateState('isSmallBusiness')" class="thick">Please select yes or no</b-form-invalid-feedback>
                            </b-form-radio-group>
                        </b-col>
                    </b-row>
                    <b-row class="mt-3">
                        <b-col lg="6">
                            <label class="thick">Home institution employment position<span
                                    class="arm-required" title="Please fill out this field."> *</span>
                            </label>
                            <p>
                                Office of Science is interested in understanding the general career stage and positions of facility users.  Please select the category that best matches your position/career stage, even if it is not your exact title.
                            </p>
                        </b-col>
                        <b-col lg="6">
                            <b-form-select v-model="people.employmentTypeCode" class="rounded-0" :options="empTypeOptions" id="employmentTypeCode" :state="validateState('employmentTypeCode')" aria-describedby="feedback" @change="empTypeChange"></b-form-select>
                            <b-form-invalid-feedback id="feedback" class="thick">Employment type is required</b-form-invalid-feedback>
                        </b-col>
                    </b-row>
                    <b-row class="mt-3">
                        <b-col lg="6">
                            <label class="thick">
                                How did you hear about the ARM user facility or ARM data?
                                <span
                                    class="arm-required"
                                    title="Please fill out this field."
                                >
                                    *
                                </span>
                            </label>
                        </b-col>
                        <b-col lg="6">
                            <b-form-select
                                v-model="people.surveyResults"
                                class="rounded-0"
                                :options="surveyOptions"
                                :state="validateState('surveyResults')"
                                aria-describedby="feedback"
                            />
                            <b-form-invalid-feedback
                                id="feedback"
                                class="thick"
                            >
                                This field is required
                            </b-form-invalid-feedback>
                        </b-col>
                    </b-row>
                </div>
            </div>
            <b-modal id="empConfirmModal" title="Please Confirm" ok-title="Confirm" no-fade ok-only hide-header-close no-close-on-backdrop centered @ok="confirmedEmpPositionModal">
                <p>
                    You have selected "Other". Please confirm that none of the above employment position categories are appropriate. Note that Office of Science is most interested in understanding the general career stage/employment position of users, not in specific position titles.
                </p>
            </b-modal>
        </div>
</template>

<script>
    import { mapState } from "vuex"
    import { validationMixin } from "vuelidate";
    import { required, requiredIf } from "vuelidate/lib/validators";
    import { VueAutosuggest } from 'vue-autosuggest';
    import Config from "@/config"

    const oneOrMoreValidator = Config.validations.oneOrMoreValidator
    const zeroOrMoreValidator = Config.validations.zeroOrMoreValidator
    const institutionsValidator = Config.validations.institutionsValidator

    export default {
        name: "InstitutionalTemplate",
        mixins: [validationMixin],
        props: {
            people: {default: null, type: Object},
            isRecordSubmitted: {default: false, type: Boolean}
        },
        components: {
            VueAutosuggest
        },
        data() {
            return {
                affiliation: {
                    id: null,
                    name: null,
                    country: null,
                },
                surveyOptions: [
                    {text:"Select one ...", value:null},
                    {text:"Colleague", value:"Colleague"},
                    {text:"Professor", value:"Professor"},
                    {text:"Search Engine", value:"Search Engine"},
                    {text:"Social Media", value:"Social Media"},
                    {text:"Journal Article or News Article", value:"Journal Article or News Article"},
                    {text:"Request for Field Campaign Announcement (EOS, BAMS)", value:"Request for Field Campaign Announcement (EOS, BAMS)"},
                    {text:"Conference or Meeting (e.g., AGU, AMS, EGU)", value:"Conference or Meeting"},
                ],
                businessOptions: [
                    { text: "Yes", value: true},
                    { text: "No", value: false}
                ],
                suggestions: [],
            }
        },
        validations: {
            people: {
                affiliation: {
                    required: requiredIf(function() {
                        return this.people?.rorId ? false : true
                    }),
                    institutionsValidator,
                },
                rorId: {
                    required: requiredIf(function() {
                        return this.people?.affiliation ? false : true
                    }),
                    institutionsValidator,
                },
                isSmallBusiness: {
                    required,
                },
                employmentTypeCode: {
                    required,
                    oneOrMoreValidator,
                },
                surveyResults: {
                    required,
                    oneOrMoreValidator,
                }
            }
        },
        computed: {
            ...mapState("app",["instOptions", "empTypeOptions"]),
            restrictedCharacters() {
              return Config.restrictedCharacters
            },
            restrictedInstitutionsCharacters() {
              return Config.restrictedInstitutionsCharacters
            },
            inputProps() {
                return {
                    id: "autosuggest__input",
                    placeholder: "Home Institution Name...",
                    class: "form-control" // Provides Bootstrap Styling to input
                }
            },
            validationObject() {
              return JSON.stringify(this.$v.people)
            },
        },
        created() {
            this.$store.dispatch("app/getEmpTypeOptions")
        },
        watch: {
            isRecordSubmitted() {
                this.$v.people.$touch();
            },
            validationObject: {
              handler(newVal, oldVal) {
                const a = JSON.parse(newVal)
                const b = JSON.parse(oldVal)
                Object.keys(a.$params).forEach(key => {
                  if (a[key].$model !== b[key].$model) {
                    this.$v.people[key].$touch()
                    this.$emit("institutionValid", !this.$v.people.$invalid)
                  }
                })
              },
              deep: true,
            },
            "people.affiliation": {
                handler(newVal, oldVal) {
                    if (newVal && !oldVal) {
                        this.fetchAffiliationInfo()
                    }
                },
                deep: true,
            },
            "people.rorId": {
                handler(newVal, oldVal) {
                    if (newVal && !oldVal) {
                        this.fetchAffiliationInfo()
                    }
                },
                deep: true,
            },
        },
        methods: {
            fetchAffiliationInfo() {
                if (this.people?.rorId) {
                    const prefix = Config.identifiers.ror_id_prefix
                    const rorId = this.people.rorId
                    const uri = prefix + rorId
                    this.$store.dispatch("app/fetchAffiliationInfo", uri).then(response => {
                        if (response?.[0]?.name) {
                            this.affiliation = response?.map(datum => {
                                return {
                                    id: rorId,
                                    name: datum?.name,
                                    country: datum?.country?.country_name,
                                }
                            })[0]
                        }
                    })
                } else if (this.people?.affiliation) {
                    this.affiliation = {
                        id: this.people.affiliation,
                        name: this.people.affiliation,
                    }
                }
            },
            fetchAffiliationOptions(filter) {
                if (filter) {
                    this.people.rorId = null
                    this.$store.dispatch("app/searchAffiliationOptions", filter.toLowerCase()).then(response => {
                        this.suggestions = [{
                            data: response?.map(datum => {
                                const prefix = Config.identifiers.ror_id_prefix
                                const uri = datum?.id
                                const id = uri.replace(prefix, "")
                                return {
                                    id,
                                    name: datum?.name,
                                    country: datum?.country?.country_name,
                                }
                            })
                        }]
                    })

                    this.affiliation = {
                        id: filter,
                        name: filter,
                    }
                    this.people.affiliation = filter
                } else {
                  this.affiliation = {
                    id: null,
                    name: null,
                  }
                  this.people.affiliation = null
                  this.people.rorId = null
                }
            },
            confirmedEmpPositionModal() {
                this.people.employmentTypeConfirm = true
            },
            empTypeChange() {
                // Display confirmation modal only when 'Other' is selected and haven't confirmed it before
                if (this.people.employmentTypeCode === "X" && !this.people.employmentTypeConfirm) {
                    this.$bvModal.show("empConfirmModal")
                } else {
                    this.people.employmentTypeConfirm = null
                }
            },
            validateState(name) {
                const {$dirty, $error} = this.$v.people[name];
                return $dirty ? !$error : null;
            },
            validateAffiliation() {
                return this.people.affiliation ? true : false
            },
            getSuggestionValue(suggestion) {
                return suggestion.item
            },
            fetchResults(searchText) {
                var result = this.instOptions.filter(val => {
                    return val.toLowerCase().startsWith(searchText.toLowerCase())
                })
                this.suggestions = [{
                    data: result
                }]
            },
            onSelected(text) {
                this.affiliation = text.item
                this.people.affiliation = text.item.name
                this.people.rorId = text.item.id
            }
        }
    }
</script>

<style>
.is-invalid .input-group input {
    border-color: #dc3545;
}

.institution-info {
    color: white;
    background-color: #5180A8;
    font-weight: bold;
    border-radius: 4px;
}
</style>
